import { SegmentItem } from '@/models/dictionary/segments.model';
import { useSegmentsStore } from '@/store/dictionary/segments.store';
import { debounce } from 'lodash';
import { storeToRefs } from 'pinia';
import { PropType, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'SegmentsAutocompleteMultiselect',
  props: {
    segmentList: {
      type: Array as PropType<SegmentItem[]>,
      default: () => [],
    },
  },
  emits: ['update:segmentList'],
  setup(props, { emit }) {
    const segmentsStore = useSegmentsStore();
    const { getAutocompleteSegmentList, clearAutocompleteSearchResult } = segmentsStore;
    const { isAutocompleteSearchLoading, autocompleteSearchResult } = storeToRefs(segmentsStore);

    const autocompleteSearch = (query: string) => {
      const performSearch = debounce(() => {
        if (query.length < 3) {
          clearAutocompleteSearchResult();

          return [];
        }

        getAutocompleteSegmentList({
          isPublished: true,
          name: query.toLowerCase(),
        });
      }, 300);

      performSearch();
    };

    const segmentListModelValue = ref<SegmentItem[]>(props.segmentList);

    return {
      emit,
      autocompleteSearch,
      isAutocompleteSearchLoading,
      autocompleteSearchResult,
      clearAutocompleteSearchResult,
      segmentListModelValue,
    };
  },
});
