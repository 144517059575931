import { PAYMENTS_SECTIONS_ROUTES } from './permissions.model';

export enum PAYMENTS_META_TITLES {
  PS_MANAGEMENT = 'PS Management',
  TRANSACTIONS = 'Transactions',
}

export const PAYMENTS_SECTIONS_ROUTES_META_TITLES = {
  [PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT]: PAYMENTS_META_TITLES.PS_MANAGEMENT,
  [PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS]: PAYMENTS_META_TITLES.TRANSACTIONS,
};
