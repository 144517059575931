<template>
  <el-container class="ps-management-page__header">
    <h2>
      {{ pageTitle }}
    </h2>
    <div class="ps-management-page__actions">
      <div class="ps-management-page__actions__geo-operations">
        <el-segmented
          v-if="!isGlobalSettingsMode"
          v-model="geoCurrentOperation"
          :options="geoPaymentMethodOperationOptions"
          size="default"
          @change="getGeoPaymentMethodList"
        >
          <template #default="{ item }">
            <span>{{ item.label }}</span>
          </template>
        </el-segmented>
      </div>
      <div
        v-if="isGetGeoPaymentMethodListAllowed"
        class="ps-management-page__country-select_wrapper"
      >
        <el-select
          ref="paymentMethodsGeoSelectRef"
          v-model="selectedCountry"
          :loading="isPsManagementCountryListLoading"
          placeholder="Global settings"
          filterable
          clearable
          value-key="code"
          width="200px"
          @change="refreshPaymentSystemList"
        >
          <el-option
            :value="''"
            label="Global settings"
            class="ps-management-page__country-select_global-settings"
            :class="{ 'ps-management-page__country-select_global-settings_active': isGlobalSettingsMode }"
          >
            Global settings
          </el-option>
          <el-option
            v-for="country in psManagementCountryList"
            :key="country.code"
            :value="country"
            :label="country.name"
          >
            {{ country.name }}
          </el-option>
          <template v-if="isAddPaymentMethodGeoAllowed" #footer>
            <el-button
              type="primary"
              :icon="icons.Plus"
              :value="null"
              class="ps-management-page__country-select_add-country-button"
              @click="openAddCountriesDialog"
            >
              Add countries
            </el-button>
          </template>
        </el-select>
      </div>
      <el-button
        v-if="isCreateGlobalPaymentMethodButtonVisible"
        type="primary"
        :icon="icons.Plus"
        @click="setGlobalPaymentMethodSettingsSidebarVisible(true)"
      >
        Create payment system
      </el-button>
      <el-button
        v-if="isCreateGeoPaymentMethodButtonVisible"
        type="primary"
        :icon="icons.Plus"
        @click="openAddGeoPaymentMethodsDialog"
      >
        Add payment method
      </el-button>
      <div class="ps-management-page__actions-round-wrapper">
        <el-button
          circle
          :icon="icons.Refresh"
          @click="refreshPaymentSystemList"
        />
        <el-button
          v-if="isUpdateGeoPaymentListButtonVisible"
          type="primary"
          round
          :icon="icons.Upload"
          @click="updateFullGeoPaymentMethodList"
        >
          Update list
        </el-button>
      </div>
    </div>
  </el-container>
  <el-container class="ps-management-page__main-content">
    <GlobalPaymentMethodsList v-if="isGlobalSettingsMode" />
    <GeoPaymentMethodsList v-else />
  </el-container>
  <GlobalPaymentMethodsSettings
    v-if="isGlobalSettingsMode && isGlobalPaymentMethodSettingsSidebarVisible"
    :update-payment-method-list="refreshPaymentSystemList"
  />
  <GeoPaymentMethodsSettings
    v-if="isGeoPaymentMethodSettingsSidebarVisible"
    :update-payment-method-list="refreshPaymentSystemList"
  />
  <PspLimitsSettings
    v-if="isPspLimitsSettingsSidebarVisible"
    :update-payment-method-list="refreshPaymentSystemList"
  />
  <AddGeoPaymentMethodsDialog v-if="isAddGeoPaymentMethodsDialogVisible" />
  <AddCountriesDialog />
</template>

<script src="./ps-management-page.ts" />
<style src="./ps-management-page.scss" />
