import { api } from '@/services/http.service';
import { defineStore } from 'pinia';
import { ElNotification, FormInstance } from 'element-plus';
import {
  CreateGlobalPaymentMethodData,
  DEFAULT_GLOBAL_PAYMENT_METHOD_FORM,
  GlobalPaymentMethodItem,
  GlobalPaymentMethodPartialUpdateParams,
  UpdateGlobalPaymentMethodData,
} from '@/models/ps-management/ps-management.model';
import { DEFAULT_OFFSET, DEFAULT_PAYMENT_METHODS_LIST_LIMIT } from '@/models/constants';
import { ref } from 'vue';
import { cloneDeep } from 'lodash';
import { useUploadImageStore } from '@/store/upload-image.store';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import { CurrencyItem } from '@/models/dictionary/currency-list.model';

interface GlobalPsManagementStore {
  globalPaymentMethodList: GlobalPaymentMethodItem[];
  isGlobalPaymentMethodListLoading: boolean;
  limit: number;
  offset: number;
  globalPaymentMethodsSettingsFormData: CreateGlobalPaymentMethodData;
  isGlobalPaymentMethodSettingsSidebarVisible: boolean;
  currentBillingPaymentMethodId: string;
  isGlobalPaymentMethodSettingsLoading: boolean;
}

export const globalPaymentMethodsSettingsFormRef = ref<FormInstance>();
export const globalPaymentMethodsCurrencyFormRef = ref<FormInstance>();

export const useGlobalPsManagementStore = defineStore({
  id: 'GlobalPsManagementStore',
  state: (): GlobalPsManagementStore => ({
    globalPaymentMethodList: [],
    isGlobalPaymentMethodListLoading: false,
    limit: DEFAULT_PAYMENT_METHODS_LIST_LIMIT,
    offset: DEFAULT_OFFSET,
    globalPaymentMethodsSettingsFormData: cloneDeep(DEFAULT_GLOBAL_PAYMENT_METHOD_FORM),
    isGlobalPaymentMethodSettingsSidebarVisible: false,
    currentBillingPaymentMethodId: '',
    isGlobalPaymentMethodSettingsLoading: false,
  }),
  getters: {
    isDepositEnabled(state): boolean {
      return state.globalPaymentMethodsSettingsFormData.depositEnabled;
    },
    isWithdrawEnabled(state): boolean {
      return state.globalPaymentMethodsSettingsFormData.withdrawEnabled;
    },
    billingProviderId(state): string {
      return state.globalPaymentMethodsSettingsFormData.billingProviderId;
    },
    availableCurrencyList(state): CurrencyItem[] {
      const { currencyList } = useCurrencyListStore();

      const selectedCurrencyList = state
        .globalPaymentMethodsSettingsFormData
        .depositLimitList
        .map(currency => currency.currency);

      return currencyList.filter(currency => !selectedCurrencyList.includes(currency.code));
    },
  },
  actions: {
    setDefaultGlobalPaymentMethodsSettingsFormData(): void {
      this.globalPaymentMethodsSettingsFormData = cloneDeep(DEFAULT_GLOBAL_PAYMENT_METHOD_FORM);
    },
    setGlobalPaymentMethodSettingsSidebarVisible(isVisible: boolean): void {
      this.isGlobalPaymentMethodSettingsSidebarVisible = isVisible;
    },
    setGlobalPaymentMethodsLoading(isLoading: boolean): void {
      if (this.isGlobalPaymentMethodListLoading === isLoading) {
        return;
      }

      this.isGlobalPaymentMethodListLoading = isLoading;
    },
    setGlobalPaymentMethodsSettingsLoading(isLoading: boolean): void {
      this.isGlobalPaymentMethodSettingsLoading = isLoading;
    },
    setOffset(offset: number): void {
      this.offset = offset;
    },
    setLimit(limit: number): void {
      this.limit = limit;
    },
    getGlobalPaymentMethodList(): Promise<GlobalPaymentMethodItem[]> {
      this.setGlobalPaymentMethodsLoading(true);

      const requestPayload = {
        limit: this.limit,
        offset: this.offset,
      };

      return api.psManagement.getGlobalPaymentMethodListRequest.perform(requestPayload)
        .then(response => {
          this.globalPaymentMethodList = response;

          return response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return ([]);
        }).finally(() => this.setGlobalPaymentMethodsLoading(false));
    },
    createGlobalPaymentMethod(paymentMethod: CreateGlobalPaymentMethodData): Promise<void> {
      const { resetImageData } = useUploadImageStore();

      this.setGlobalPaymentMethodsSettingsLoading(true);

      return api.psManagement.createGlobalPaymentMethodRequest.perform(paymentMethod)
        .then(() => {
          ElNotification({
            title: 'Success',
            message: 'Payment method created',
            type: 'success',
          });

          resetImageData();
          this.getGlobalPaymentMethodList();
          this.closeGlobalPaymentMethodSettings();
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.response.data.message,
            type: 'error',
          });
        }).finally(() => {
          this.setGlobalPaymentMethodsSettingsLoading(false);
        });
    },
    partialUpdateGlobalPaymentMethod(payload: GlobalPaymentMethodPartialUpdateParams): Promise<void> {
      this.setGlobalPaymentMethodsLoading(true);

      const { parameterKey, billingPaymentMethodId, newValue } = payload;

      const payloadData = {
        [parameterKey]: newValue,
      };

      return api.psManagement.partialUpdateGlobalPaymentMethodRequest.perform(
        { billingPaymentMethodId, data: payloadData },
      ).then(() => {
        ElNotification({
          title: 'Success',
          message: 'Payment method updated',
          type: 'success',
        });
      }).finally(() => {
        this.getGlobalPaymentMethodList();
        this.setGlobalPaymentMethodsLoading(false);
      });
    },
    updateGlobalPaymentMethod(globalPaymentMethodSettingsData: UpdateGlobalPaymentMethodData) {
      const { resetImageData } = useUploadImageStore();

      this.setGlobalPaymentMethodsSettingsLoading(true);

      return api.psManagement.updateGlobalPaymentMethodRequest.perform(
        this.currentBillingPaymentMethodId, globalPaymentMethodSettingsData,
      ).then(() => {
        ElNotification({
          title: 'Success',
          message: 'Payment method updated',
          type: 'success',
        });
        resetImageData();
        this.closeGlobalPaymentMethodSettings();
        this.getGlobalPaymentMethodList();
      })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.response.data.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.setGlobalPaymentMethodsSettingsLoading(false);
        });
    },
    getGlobalPaymentMethod(billingPaymentMethodId: string): Promise<CreateGlobalPaymentMethodData | void> {
      this.setGlobalPaymentMethodsSettingsLoading(true);

      this.setCurrentBillingPaymentMethodId(billingPaymentMethodId);

      return api.psManagement.getGlobalPaymentMethodRequest.perform(billingPaymentMethodId)
        .then((response: CreateGlobalPaymentMethodData) => {
          this.globalPaymentMethodsSettingsFormData = response;

          return response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        }).finally(() => this.setGlobalPaymentMethodsSettingsLoading(false));
    },
    setCurrentBillingPaymentMethodId(billingPaymentMethodId: string): void {
      this.currentBillingPaymentMethodId = billingPaymentMethodId;
    },
    openGeneralPaymentMethodSettings(billingPaymentMethodId: string) {
      const { getFileUrl, setOldImageUrl } = useUploadImageStore();

      this.setGlobalPaymentMethodSettingsSidebarVisible(true);

      this.getGlobalPaymentMethod(billingPaymentMethodId).then(() => {
        if (this.globalPaymentMethodsSettingsFormData.imageId) {
          getFileUrl(this.globalPaymentMethodsSettingsFormData.imageId)
            .then((url) => {
              setOldImageUrl(url);
            });
        }
      });
    },
    closeGlobalPaymentMethodSettings(): void {
      const { resetImageData } = useUploadImageStore();

      resetImageData();
      this.setDefaultGlobalPaymentMethodsSettingsFormData();
      this.setCurrentBillingPaymentMethodId('');
      this.setGlobalPaymentMethodSettingsSidebarVisible(false);
    },
  },
});
