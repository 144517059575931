import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import {
  BillingPaymentMethodDepositLimit,
  GeoPaymentMethodDepositLimitsRequestPayload,
} from '@/models/ps-management/limits.model';

export default
class GetGeoPaymentMethodDepositLimitsListRequest extends ApiResourceMethod<BillingPaymentMethodDepositLimit[]> {
  public perform(params: GeoPaymentMethodDepositLimitsRequestPayload): Promise<BillingPaymentMethodDepositLimit[]> {
    return this.performer.requestWithMapper(
      { params },
      (payload: BaseResponse<BillingPaymentMethodDepositLimit[]>) => {
        return payload.data;
      },
    );
  }
}
