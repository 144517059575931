import { useGeoPsManagementStore } from '@/store/ps-management/geo-payment-methods.store';
import { storeToRefs } from 'pinia';
// eslint-disable-next-line import/no-named-as-default
import Sortable, { SortableEvent } from 'sortablejs';
import { Ref, ref } from 'vue';

interface DragListSortingHook {
  isResortingInProgress: Ref<boolean>;
  dragOptions: Sortable.Options;
  dragSortingTableRef: Ref<HTMLTableElement>;
}

const dragSortingTableRef = ref();
const isResortingInProgress = ref<boolean>(false);

const dragOptions: Sortable.Options = {
  animation: 150,
  // filter — это игнорируемые элементы, которые нельзя перетаскивать в таблице
  filter: '.ps-limits-details__wrapper',
  // handle — это элемент, который является ручкой для перетаскивания строк таблицы
  handle: '.geo-payment-methods__drag-handle',
  onEnd({ newIndex, oldIndex }: SortableEvent) {
    if (newIndex === oldIndex) {
      return;
    }

    const { geoPaymentMethodList } = storeToRefs(useGeoPsManagementStore());

    const targetRow = geoPaymentMethodList.value.splice(oldIndex as number, 1)[0];
    geoPaymentMethodList.value.splice(newIndex as number, 0, targetRow);
    isResortingInProgress.value = true;
  },
};

export function useDragListSorting(): DragListSortingHook {
  return {
    isResortingInProgress,
    dragOptions,
    dragSortingTableRef,
  };
}
