import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { GeoPaymentMethodUpdateRequestPayload } from '@/models/ps-management/ps-management.model';

export default class UpdateGeoPaymentMethodRequest
  extends ApiResourceMethod<{}> {
  public perform(payload: GeoPaymentMethodUpdateRequestPayload): Promise<{}> {
    return this.performer.requestWithMapper(
      {
        url: `${payload.billingPaymentMethodSettingsId}`,
        data: payload.paymentMethodData,
      },
      (response: BaseResponse<{}>) => {
        return response.data;
      },
    );
  }
}
