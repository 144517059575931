import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "token-pagination" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_icon = _resolveComponent("el-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_select, {
      modelValue: _ctx.itemsPerPage,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.itemsPerPage) = $event)),
      class: "token-pagination_items",
      size: "small",
      onChange: _ctx.updateItemsPerPage
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.DEFAULT_ITEMS_PER_PAGE_LIST, (amount) => {
          return (_openBlock(), _createBlock(_component_el_option, {
            key: amount,
            label: `${amount}/page`,
            value: amount
          }, null, 8 /* PROPS */, ["label", "value"]))
        }), 128 /* KEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "onChange"]),
    _createElementVNode("div", null, [
      _createVNode(_component_el_button, {
        disabled: !_ctx.prevPageToken,
        icon: _ctx.icons.DArrowLeft,
        size: "small",
        type: "primary",
        text: "",
        class: "token-pagination_button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changePageHandler('default')))
      }, null, 8 /* PROPS */, ["disabled", "icon"]),
      _createVNode(_component_el_button, {
        disabled: !_ctx.prevPageToken,
        icon: _ctx.icons.ArrowLeft,
        size: "small",
        type: "primary",
        text: "",
        class: "token-pagination_button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePageHandler('prev')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Previous ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "icon"]),
      _createVNode(_component_el_button, {
        disabled: !_ctx.nextPageToken,
        size: "small",
        type: "primary",
        text: "",
        class: "token-pagination_button",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePageHandler('next')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Next "),
          _createVNode(_component_el_icon, { class: "el-icon--right" }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icons.ArrowRight)))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled"])
    ])
  ]))
}