import { api } from '@/services/http.service';
import { defineStore } from 'pinia';

interface TwoFactorStore {
  twoFactorCode: string | null;
  isTwoFactorDialogVisible: boolean;
  isTwoFactorAuthLoading: boolean;
}

export const useTwoFactorStore = defineStore({
  id: 'TwoFactorStore',
  state: (): TwoFactorStore => ({
    twoFactorCode: null,
    isTwoFactorDialogVisible: false,
    isTwoFactorAuthLoading: false,
  }),
  getters: {
    isEnteredCodeFormallyValid(): boolean {
      const regex = /^\d{6}$/;

      return !!(this.twoFactorCode && regex.test(this.twoFactorCode));
    },
  },
  actions: {
    setTwoFactorCode(code: string): void {
      this.twoFactorCode = code;
    },
    setTwoFactorDialogVisibility(isVisible: boolean): void {
      this.isTwoFactorDialogVisible = isVisible;
    },
    openTwoFactorDialog(): void {
      this.setTwoFactorDialogVisibility(true);
    },
    closeTwoFactorDialog(): void {
      this.twoFactorCode = null;
      this.setTwoFactorDialogVisibility(false);
    },
    checkTwoFactorCode(code: string): Promise<boolean> {
      this.isTwoFactorAuthLoading = true;

      return api.auth.twoFactorCodeRequest.perform({ code })
        .then((isCodeValid) => {
          return isCodeValid;
        })
        .finally(() => {
          this.isTwoFactorAuthLoading = false;
        });
    },
  },
});
