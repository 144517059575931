export type BillingProviderItem = {
  bpId: string;
  name: string;
  label: string;
  allowedIpList: string[];
  depositMethodList: string[];
  withdrawMethodList: string[];
}

export type GetBillingProviderListPayload = {
  limit: number;
  offset: number;
}

export const DEFAULT_BILLING_PROVIDER_PAYLOAD: GetBillingProviderListPayload = {
  limit: 1000,
  offset: 0,
};
