<template>
  <el-config-provider namespace="ep-payments">
    <div ref="root" class="payments-container">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script src="./root-component.ts" />
<style src="./root-component.scss" />
