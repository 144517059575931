import { defineStore } from 'pinia';
import { ElNotification } from 'element-plus';
import { api } from '@/services/http.service';
import { PaymentTypeItem } from '@/models/dictionary/payment-type-list.model';

interface PaymentTypeListState {
  paymentTypeList: PaymentTypeItem[];
  isPaymentTypeListLoading: boolean;
}

export const usePaymentTypeListStore = defineStore({
  id: 'paymentTypeListStore',
  state: (): PaymentTypeListState => ({
    paymentTypeList: [],
    isPaymentTypeListLoading: false,
  }),
  actions: {
    getPaymentTypeLabel(value: PaymentTypeItem['value']) {
      if (!value) {
        return '';
      }

      const foundLabel = this.paymentTypeList.find(paymentType => paymentType.value === value)?.label;

      return foundLabel ?? value;
    },
    getPaymentTypeListRequest(): Promise<PaymentTypeItem[] | void> {
      if (this.paymentTypeList.length > 0) {
        return Promise.resolve(this.paymentTypeList);
      }

      this.isPaymentTypeListLoading = true;

      return api.dictionary.getPaymentTypeListRequest.perform()
        .then((data) => {
          this.paymentTypeList = data;
        })
        .catch(error => {
          ElNotification.error({
            title: 'Error',
            message: error.message,
          });

          return Promise.reject(error);
        })
        .finally(() => {
          this.isPaymentTypeListLoading = false;
        });
    },
  },
});
