import { PAYMENTS_SECTIONS_ROUTES } from '@/models/app-internal/permissions.model';
import { RouteComponent, RouteRecordRaw } from 'vue-router';

export const psManagementRoutes: RouteRecordRaw[] = [
  {
    name: PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT,
    path: `/${PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT}`,
    component: async (): Promise<RouteComponent> =>
      await import('@/layouts/ps-management-page/ps-management-page.vue'),
  },
];
