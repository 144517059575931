/* eslint-disable no-param-reassign */
import { ApiResourcesFactory } from '@/api/api-resources-factory';
import { apiRequestFactory } from '@/api/api-requests-factory';
import { useAuth } from '@/store/auth.store';
import { useEnvStore } from '@/store/env.store';
import { storeToRefs } from 'pinia';
import router from '@/router/router';
import { authErrorCodes, authForbiddenCode } from '@/models/auth/auth.model';
import { PAYMENTS_MODES } from '@/models/app-internal/env.model';
import { ElNotification } from 'element-plus';

export const apiResourcesFactory = new ApiResourcesFactory();
export const http = apiRequestFactory.getRequestPerformer();

http.setInterceptor({
  request: {
    onFulfilled(config) {
      const { token } = storeToRefs(useAuth());

      if (!config.headers) {
        config.headers = {};
      }

      if (token.value) {
        config.headers['X-Auth-Token'] = token.value;
      }

      return config;
    },
  },
  response: {
    onFulfilled(response) {
      const fileContentTypes = ['text/csv; charset=UTF-8'];

      if (fileContentTypes.includes(response.headers['content-type'])) {
        response.data = { fileData: response.data };
      }

      if (response.data.textCode) {
        ElNotification({
          title: 'Error',
          message: response.data.message,
          type: 'error',
        });
        throw new Error(response.data.message);
      }

      return response;
    },
    onRejected(error) {
      ElNotification({
        title: 'Error',
        message: error.response.data.message,
        type: 'error',
      });

      if (authErrorCodes.includes(error.response.status)) {
        const { setToken } = useAuth();
        const { APP_MODE } = useEnvStore();

        setToken('');

        const isReload = APP_MODE === PAYMENTS_MODES.BACKOFFICE;
        if (isReload) {
          router.go(0);
        }
      }

      if (error.response.status === authForbiddenCode) {
        router.push({ name: 'home' });
      }

      throw error;
    },
  },
});

export const api = apiResourcesFactory.getInstance(http);
