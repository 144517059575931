import { LimitItem, LimitType, LimitTypeKey } from '@/models/ps-management/limits.model';
import { PropType, defineComponent, ref } from 'vue';
import { Plus, Delete } from '@element-plus/icons-vue';
import SegmentsAutocompleteMultiselect
  from '@/components/segments-autocomplete-multiselect/segments-autocomplete-multiselect.vue';
import { pspLimitsSettingsFormRules } from '@/hooks/ps-management/deposit-limits-validation-rules';
import { usePaymentMethodsDepositLimitsStore } from '@/store/ps-management/payment-methods-deposit-limits.store';
import { storeToRefs } from 'pinia';

const icons = {
  Plus,
  Delete,
};

export default defineComponent({
  name: 'PspLimit',
  components: {
    SegmentsAutocompleteMultiselect,
  },
  props: {
    title: {
      type: String,
      default: 'PSP limit',
    },
    limitIndex: {
      type: Number,
      required: true,
    },
    limitTypeKey: {
      type: String as PropType<keyof typeof LimitTypeKey>,
      required: true,
    },
  },
  setup(props) {
    const activeLimits = ref([]);

    const paymentMethodsDepositLimitsStore = usePaymentMethodsDepositLimitsStore();
    const { depositLimitsData, currentGeoPaymentMethodId } = storeToRefs(paymentMethodsDepositLimitsStore);

    const addLimitItem = () => {
      depositLimitsData
        .value[currentGeoPaymentMethodId.value]
        .depositLimitsList[props.limitIndex][props.limitTypeKey]
        .push({
          segmentList: [],
          limitValue: null,
          limitType: LimitType.AMOUNT,
        });
    };

    const deleteLimitItem = (index: number): void => {
      depositLimitsData
        .value[currentGeoPaymentMethodId.value]
        .depositLimitsList[props.limitIndex][props.limitTypeKey].splice(index, 1);
    };

    const checkLimitValue = (limit: LimitItem): boolean => {
      const isLimitValueValid = limit.limitValue && Number(limit.limitValue) > 0;

      return !!isLimitValueValid;
    };

    return {
      LimitType,
      activeLimits,
      icons,
      addLimitItem,
      deleteLimitItem,
      checkLimitValue,
      pspLimitsSettingsFormRules,
      currentGeoPaymentMethodId,
      depositLimitsData,
    };
  },
});
