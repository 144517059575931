import { PaymentAccountListItem } from '@/models/dictionary/payment-account-list.model';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { defineStore } from 'pinia';

interface PaymentAccountListState {
  paymentAccountList: PaymentAccountListItem[];
  isPaymentAccountListLoading: boolean;
}

export const usePaymentAccountListStore = defineStore({
  id: 'PaymentAccountListStore',
  state: (): PaymentAccountListState => ({
    paymentAccountList: [],
    isPaymentAccountListLoading: false,
  }),
  actions: {
    setPaymentAccountListLoading(isLoading: boolean): void {
      this.isPaymentAccountListLoading = isLoading;
    },
    getPaymentAccountList(): Promise<PaymentAccountListItem[] | void> {
      if (this.paymentAccountList.length > 0) {
        return Promise.resolve(this.paymentAccountList);
      }

      this.setPaymentAccountListLoading(true);

      return api.dictionary.getPaymentAccountListRequest.perform()
        .then((data) => {
          this.paymentAccountList = data;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return Promise.reject(error);
        })
        .finally(() => {
          this.setPaymentAccountListLoading(false);
        });
    },
  },
});
