export type ColumnFilterItem = {
  label: string;
  value: string;
  isVisible?: boolean;
  width?: number;
  fixed?: string;
  isSortable?: boolean;
  align?: string;
  minWidth?: number;
};

export enum ColumnFilterEvents {
  UPDATE_COLUMNS = 'updateColumns',
  RESET_COLUMNS = 'resetColumns',
}

export enum ColumnFilterAlignments {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}
