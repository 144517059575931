import { computed, defineComponent, onMounted } from 'vue';
import LimitsCell from './components/limits-cell/limits-cell.vue';
import BooleanCell from './components/boolean-cell/boolean-cell';
import { storeToRefs } from 'pinia';
import { usePaymentMethodsDepositLimitsStore } from '@/store/ps-management/payment-methods-deposit-limits.store';

export default defineComponent({
  name: 'GlobalPaymentMethodsList',
  components: {
    LimitsCell,
    BooleanCell,
  },
  props: {
    paymentMethodSettingsId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const paymentsDepositLimitsStore = usePaymentMethodsDepositLimitsStore();
    const { getGeoPaymentMethodDepositLimits } = paymentsDepositLimitsStore;
    const { depositLimitsData, isDepositLimitsLoading } = storeToRefs(paymentsDepositLimitsStore);

    onMounted(() => {
      if (!props.paymentMethodSettingsId) {
        return;
      }

      getGeoPaymentMethodDepositLimits(props.paymentMethodSettingsId);
    });

    const depositLimits = computed(() => {
      if (!depositLimitsData.value[props.paymentMethodSettingsId]) {
        return [];
      }

      return depositLimitsData.value[props.paymentMethodSettingsId].depositLimitsList;
    });

    return {
      depositLimits,
      isDepositLimitsLoading,
    };
  },
});
