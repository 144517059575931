export const clearPayloadData = <T>(obj: T): T | {} => {
  const excludeFields = ['offset'];

  // @ts-ignore
  return Object.entries(obj).reduce((input, [key, value]) => {
    const result = { ...input };
    if (value || excludeFields.includes(key)) {
      result[key] = value;
    }

    return result;
  }, {});
};
