import { reactive } from 'vue';

export const globalPaymentMethodsFormRules = reactive({
  paymentSystem: [
    { required: true, message: 'Name is required', trigger: 'blur' },
  ],
  billingProviderId: [
    { required: true, message: 'Billing provider is required', trigger: 'change' },
  ],
  psp: [
    { required: true, message: 'PSP is required', trigger: 'change' },
  ],
  paymentTypes: [
    { required: true, message: 'Payment type is required', trigger: 'change' },
  ],
});

export const globalPaymentMethodsFormCurrencyRules =
  { required: true, message: 'Please select currency', trigger: 'change' };
