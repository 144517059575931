import { CountryItem } from '@/models/dictionary/country-list.model';
import { ColumnFilterAlignments, ColumnFilterItem } from '@/models/column-filter/column-filter.model';
import { GeoPaymentMethodDepositLimits } from '@/models/ps-management/limits.model';
import { SegmentItem } from '@/models/dictionary/segments.model';
import { PaymentTypeItem } from '@/models/dictionary/payment-type-list.model';

export type GlobalPaymentMethodItem = {
  billingPaymentMethodId: string;
  vaultEnabled: boolean;
  psp: string;
  billingProviderName: string;
  paymentSystem: string;
  imageId: string;
  filePath: string;
  depositEnabled: boolean;
  withdrawEnabled: boolean;
  segmentList: SegmentItem[];
  excludedSegmentList: SegmentItem[];
  depositLimitList: LimitListItem[];
  withdrawLimitList: LimitListItem[];
  billingProviderId: string;
  paymentTypes: PaymentTypeItem[];
};

export type CreateGlobalPaymentMethodData =
  Omit<GlobalPaymentMethodItem, 'billingPaymentMethodId' | 'vaultEnabled' | 'billingProviderName' | 'filePath'>;

export type UpdateGlobalPaymentMethodData =
  Omit<GlobalPaymentMethodItem, 'billingPaymentMethodId' | 'billingProviderName' | 'filePath'>;

export const DEFAULT_GLOBAL_PAYMENT_METHOD_FORM: CreateGlobalPaymentMethodData = {
  billingProviderId: '',
  psp: '',
  paymentSystem: '',
  imageId: '',
  depositEnabled: false,
  withdrawEnabled: false,
  segmentList: [],
  excludedSegmentList: [],
  depositLimitList: [],
  withdrawLimitList: [],
  paymentTypes: [],
};

export type GeoPaymentMethodItem =
  Omit<GlobalPaymentMethodItem, 'vaultEnabled'>
  & {
    billingPaymentMethodSettingsId: string;
    sortingWeight: number;
    publicName: string;
    depositLimits?: GeoPaymentMethodDepositLimits;
    country: string;
    imageUrl: string;
    operation: string;
  };

export const DEFAULT_GEO_PAYMENT_METHOD_FORM: GeoPaymentMethodItem = {
  billingProviderId: '',
  billingPaymentMethodId: '',
  billingProviderName: '',
  sortingWeight: 0,
  publicName: '',
  billingPaymentMethodSettingsId: '',
  psp: '',
  paymentSystem: '',
  imageId: '',
  filePath: '',
  imageUrl: '',
  operation: '',
  depositEnabled: false,
  withdrawEnabled: false,
  segmentList: [],
  excludedSegmentList: [],
  depositLimitList: [],
  withdrawLimitList: [],
  country: 'EN',
  paymentTypes: [],
};

export type LimitListItem = {
  currency: string;
  min: number;
  max: number;
};

export const DEFAULT_CURRENCY_ITEM: LimitListItem = {
  min: 0,
  max: 0,
  currency: '',
};

export type GeoPaymentMethodList = GeoPaymentMethodItem[];

export type GlobalPaymentMethodList = GlobalPaymentMethodItem[];

export type GetGeoPaymentMethodListRequestPayload = {
  limit: number;
  offset: number;
  filterList: {
    country: string;
    operation: GeoPaymentMethodOperation;
  };
}

export type GeoPaymentMethodUpdateRequestPayload = {
  billingPaymentMethodSettingsId: string;
  paymentMethodData: GeoPaymentMethodItem;
};

export type GeoPaymentMethodPartialUpdatePayload = {
  billingPaymentMethodSettingsId: string;
  data: {
    depositEnabled?: boolean;
    withdrawEnabled?: boolean;
  };
};

export enum GeoPaymentMethodOperation {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
  ALL = 'all',
}

export const geoPaymentMethodOperationOptions = [
  {
    label: 'Deposit',
    value: GeoPaymentMethodOperation.DEPOSIT,
  },
  {
    label: 'Cash out',
    value: GeoPaymentMethodOperation.WITHDRAW,
  },
  {
    label: 'All',
    value: null,
  },
];

export type UpdateListSortGeoPaymentMethodRequestPayload = {
  country: CountryItem['code'];
  operation: GeoPaymentMethodOperation;
  paymentMethodSettingsList: Array<{ sortingWeight: number; billingPaymentMethodSettingsId: string }>;
};

export type GetGlobalPaymentMethodListRequestPayload = {
  limit: number;
  offset: number;
};

export type GlobalPaymentMethodPartialUpdatePayload = {
  billingPaymentMethodId: string;
  data: {
    depositEnabled?: boolean;
    withdrawEnabled?: boolean;
  };
};

export type GlobalPaymentMethodPartialUpdateParameterKeyModel = GlobalPaymentMethodColumnProperties.WITHDRAW_ENABLED |
GlobalPaymentMethodColumnProperties.DEPOSIT_ENABLED;

export type GlobalPaymentMethodPartialUpdateParams = {
  parameterKey: GlobalPaymentMethodPartialUpdateParameterKeyModel;
  billingPaymentMethodId: GlobalPaymentMethodItem['billingPaymentMethodId'];
  newValue: boolean;
}

export type GeoPaymentMethodPartialUpdateParameterKeyModel = GeoPaymentMethodColumnProperties.WITHDRAW_ENABLED |
GeoPaymentMethodColumnProperties.DEPOSIT_ENABLED;

export type GeoPaymentMethodPartialUpdateParams = {
  parameterKey: GeoPaymentMethodPartialUpdateParameterKeyModel;
  billingPaymentMethodSettingsId: GeoPaymentMethodItem['billingPaymentMethodSettingsId'];
  newValue: boolean;
}

export enum GlobalPaymentMethodColumnProperties {
  IMAGE_URL = 'imageUrl',
  PAYMENT_SYSTEM = 'paymentSystem',
  BILLING_PROVIDER_NAME = 'billingProviderName',
  PAYMENT_TYPE_NAME = 'paymentTypes',
  SEGMENT_LIST = 'segmentList',
  EXCLUDED_SEGMENT_LIST = 'excludedSegmentList',
  DEPOSIT_LIMIT_LIST = 'depositLimitList',
  WITHDRAW_LIMIT_LIST = 'withdrawLimitList',
  DEPOSIT_ENABLED = 'depositEnabled',
  WITHDRAW_ENABLED = 'withdrawEnabled',
  ACTIONS = 'actions',
}

export enum GlobalPaymentMethodColumnLabels {
  IMAGE_URL = 'Logo',
  BRAND = 'Brand',
  PAYMENT_SYSTEM = 'Name',
  BILLING_PROVIDER_NAME = 'Billing provider',
  PAYMENT_TYPE_NAME = 'Payment type',
  SEGMENT_LIST = 'Included segments',
  EXCLUDED_SEGMENT_LIST = 'Excluded segments',
  DEPOSIT_LIMIT_LIST = 'Deposit limit',
  WITHDRAW_LIMIT_LIST = 'Cash out limit',
  DEPOSIT_ENABLED = 'Deposit',
  WITHDRAW_ENABLED = 'Cash out',
  ACTIONS = 'Actions',
}

export const globalPaymentMethodColumnLabels = {
  [GlobalPaymentMethodColumnProperties.IMAGE_URL]: GlobalPaymentMethodColumnLabels.IMAGE_URL,
  [GlobalPaymentMethodColumnProperties.PAYMENT_SYSTEM]: GlobalPaymentMethodColumnLabels.PAYMENT_SYSTEM,
  [GlobalPaymentMethodColumnProperties.BILLING_PROVIDER_NAME]: GlobalPaymentMethodColumnLabels.BILLING_PROVIDER_NAME,
  [GlobalPaymentMethodColumnProperties.PAYMENT_TYPE_NAME]: GlobalPaymentMethodColumnLabels.PAYMENT_TYPE_NAME,
  [GlobalPaymentMethodColumnProperties.SEGMENT_LIST]: GlobalPaymentMethodColumnLabels.SEGMENT_LIST,
  [GlobalPaymentMethodColumnProperties.EXCLUDED_SEGMENT_LIST]: GlobalPaymentMethodColumnLabels.EXCLUDED_SEGMENT_LIST,
  [GlobalPaymentMethodColumnProperties.DEPOSIT_LIMIT_LIST]: GlobalPaymentMethodColumnLabels.DEPOSIT_LIMIT_LIST,
  [GlobalPaymentMethodColumnProperties.WITHDRAW_LIMIT_LIST]: GlobalPaymentMethodColumnLabels.WITHDRAW_LIMIT_LIST,
  [GlobalPaymentMethodColumnProperties.DEPOSIT_ENABLED]: GlobalPaymentMethodColumnLabels.DEPOSIT_ENABLED,
  [GlobalPaymentMethodColumnProperties.WITHDRAW_ENABLED]: GlobalPaymentMethodColumnLabels.WITHDRAW_ENABLED,
  [GlobalPaymentMethodColumnProperties.ACTIONS]: GlobalPaymentMethodColumnLabels.ACTIONS,
};

export const globalPaymentMethodColumnsList: ColumnFilterItem[] = [
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.IMAGE_URL],
    value: GlobalPaymentMethodColumnProperties.IMAGE_URL,
    align: ColumnFilterAlignments.CENTER,
    width: 120,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.PAYMENT_SYSTEM],
    value: GlobalPaymentMethodColumnProperties.PAYMENT_SYSTEM,
    width: 130,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.BILLING_PROVIDER_NAME],
    value: GlobalPaymentMethodColumnProperties.BILLING_PROVIDER_NAME,
    width: 130,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.PAYMENT_TYPE_NAME],
    value: GlobalPaymentMethodColumnProperties.PAYMENT_TYPE_NAME,
    width: 215,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.SEGMENT_LIST],
    value: GlobalPaymentMethodColumnProperties.SEGMENT_LIST,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.EXCLUDED_SEGMENT_LIST],
    value: GlobalPaymentMethodColumnProperties.EXCLUDED_SEGMENT_LIST,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.DEPOSIT_LIMIT_LIST],
    value: GlobalPaymentMethodColumnProperties.DEPOSIT_LIMIT_LIST,
    width: 140,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.WITHDRAW_LIMIT_LIST],
    value: GlobalPaymentMethodColumnProperties.WITHDRAW_LIMIT_LIST,
    width: 140,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.DEPOSIT_ENABLED],
    value: GlobalPaymentMethodColumnProperties.DEPOSIT_ENABLED,
    align: ColumnFilterAlignments.CENTER,
    width: 140,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.WITHDRAW_ENABLED],
    value: GlobalPaymentMethodColumnProperties.WITHDRAW_ENABLED,
    align: ColumnFilterAlignments.CENTER,
    width: 140,
  },
  {
    label: globalPaymentMethodColumnLabels[GlobalPaymentMethodColumnProperties.ACTIONS],
    value: GlobalPaymentMethodColumnProperties.ACTIONS,
    align: ColumnFilterAlignments.CENTER,
    width: 70,
  },
];

export enum GeoPaymentMethodColumnProperties {
  IMAGE_URL = 'imageUrl',
  PAYMENT_SYSTEM = 'paymentSystem',
  PUBLIC_NAME = 'publicName',
  BILLING_PROVIDER_NAME = 'billingProviderName',
  SEGMENT_LIST = 'segmentList',
  EXCLUDED_SEGMENT_LIST = 'excludedSegmentList',
  DEPOSIT_LIMIT_LIST = 'depositLimitList',
  WITHDRAW_LIMIT_LIST = 'withdrawLimitList',
  DEPOSIT_ENABLED = 'depositEnabled',
  WITHDRAW_ENABLED = 'withdrawEnabled',
  ACTIONS = 'actions',
}

export enum GeoPaymentMethodColumnLabels {
  IMAGE_URL = 'Logo',
  BRAND = 'Brand',
  PAYMENT_SYSTEM = 'Internal name',
  PUBLIC_NAME = 'Public name',
  BILLING_PROVIDER_NAME = 'Billing provider',
  SEGMENT_LIST = 'Included segments',
  EXCLUDED_SEGMENT_LIST = 'Excluded segments',
  DEPOSIT_LIMIT_LIST = 'Deposit limit',
  WITHDRAW_LIMIT_LIST = 'Cash out limit',
  DEPOSIT_ENABLED = 'Deposit',
  WITHDRAW_ENABLED = 'Cash out',
  ACTIONS = 'Actions',
}

export const geoPaymentMethodColumnLabels = {
  [GeoPaymentMethodColumnProperties.IMAGE_URL]: GeoPaymentMethodColumnLabels.IMAGE_URL,
  [GeoPaymentMethodColumnProperties.PAYMENT_SYSTEM]: GeoPaymentMethodColumnLabels.PAYMENT_SYSTEM,
  [GeoPaymentMethodColumnProperties.PUBLIC_NAME]: GeoPaymentMethodColumnLabels.PUBLIC_NAME,
  [GeoPaymentMethodColumnProperties.BILLING_PROVIDER_NAME]: GeoPaymentMethodColumnLabels.BILLING_PROVIDER_NAME,
  [GeoPaymentMethodColumnProperties.SEGMENT_LIST]: GeoPaymentMethodColumnLabels.SEGMENT_LIST,
  [GeoPaymentMethodColumnProperties.EXCLUDED_SEGMENT_LIST]: GeoPaymentMethodColumnLabels.EXCLUDED_SEGMENT_LIST,
  [GeoPaymentMethodColumnProperties.DEPOSIT_LIMIT_LIST]: GeoPaymentMethodColumnLabels.DEPOSIT_LIMIT_LIST,
  [GeoPaymentMethodColumnProperties.WITHDRAW_LIMIT_LIST]: GeoPaymentMethodColumnLabels.WITHDRAW_LIMIT_LIST,
  [GeoPaymentMethodColumnProperties.DEPOSIT_ENABLED]: GeoPaymentMethodColumnLabels.DEPOSIT_ENABLED,
  [GeoPaymentMethodColumnProperties.WITHDRAW_ENABLED]: GeoPaymentMethodColumnLabels.WITHDRAW_ENABLED,
  [GeoPaymentMethodColumnProperties.ACTIONS]: GeoPaymentMethodColumnLabels.ACTIONS,
};

export const geoPaymentMethodColumnsList: ColumnFilterItem[] = [
  {
    label: geoPaymentMethodColumnLabels[GeoPaymentMethodColumnProperties.IMAGE_URL],
    value: GeoPaymentMethodColumnProperties.IMAGE_URL,
    align: ColumnFilterAlignments.CENTER,
    width: 120,
  },
  {
    label: geoPaymentMethodColumnLabels[GeoPaymentMethodColumnProperties.PAYMENT_SYSTEM],
    value: GeoPaymentMethodColumnProperties.PAYMENT_SYSTEM,
    width: 130,
  },
  {
    label: geoPaymentMethodColumnLabels[GeoPaymentMethodColumnProperties.PUBLIC_NAME],
    value: GeoPaymentMethodColumnProperties.PUBLIC_NAME,
    width: 130,
  },
  {
    label: geoPaymentMethodColumnLabels[GeoPaymentMethodColumnProperties.BILLING_PROVIDER_NAME],
    value: GeoPaymentMethodColumnProperties.BILLING_PROVIDER_NAME,
    width: 130,
  },
  {
    label: geoPaymentMethodColumnLabels[GeoPaymentMethodColumnProperties.SEGMENT_LIST],
    value: GeoPaymentMethodColumnProperties.SEGMENT_LIST,
  },
  {
    label: geoPaymentMethodColumnLabels[GeoPaymentMethodColumnProperties.EXCLUDED_SEGMENT_LIST],
    value: GeoPaymentMethodColumnProperties.EXCLUDED_SEGMENT_LIST,
  },
];
