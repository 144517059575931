import { SegmentItem } from '@/models/dictionary/segments.model';
import { cloneDeep } from 'lodash';

export enum LimitType {
  NUMBER = 'number',
  AMOUNT = 'amount',
}

export enum LimitTypeKey {
  DAILY = 'limitDaily',
  WEEKLY = 'limitWeekly',
  MONTHLY = 'limitMonthly',
}

export type LimitItem = {
  id?: string;
  segmentList: SegmentItem[];
  limitType: LimitType;
  limitValue: number | null;
}

export type GeoPaymentMethodDepositLimits = {
  currency: string | null;
  hidePaymentMethod: boolean;
  pendingInclude: boolean;
  limitDaily: LimitItem[];
  limitWeekly: LimitItem[];
  limitMonthly: LimitItem[];
}

export type BillingPaymentMethodDepositLimit = {
  billingPaymentMethodSettingsId: string;
  depositLimitList: GeoPaymentMethodDepositLimits[];
};

export type GeoPaymentMethodDepositLimitsRequestPayload = {
  filterList: {
    country: string;
  };
}

export type PspLimitsSettingsModel = {
  depositLimitList: GeoPaymentMethodDepositLimits[];
};

export const geoPaymentsDepositLimitDefaults: GeoPaymentMethodDepositLimits = {
  currency: null,
  hidePaymentMethod: false,
  pendingInclude: false,
  limitDaily: [],
  limitWeekly: [],
  limitMonthly: [],
};

export const DEFAULT_PSP_LIMITS_SETTINGS_FORM: PspLimitsSettingsModel = {
  depositLimitList: [
    cloneDeep(geoPaymentsDepositLimitDefaults),
  ],
};
