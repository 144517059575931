import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import TwoFactorCodeRequest from '@/api/auth/two-factor-code.request';
import { Ref } from 'vue';

export class AuthApiResources {
  constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly apiUrl: Ref<string>,
  ) {}

  public get twoFactorCodeRequest(): TwoFactorCodeRequest {
    return new TwoFactorCodeRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/admin/twoFactor/code`,
      HttpMethods.POST,
    );
  }
}
