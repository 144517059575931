import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { PaymentListItem } from '@/models/dictionary/payment-list.model';

export default class GetPaymentListRequest extends ApiResourceMethod<PaymentListItem[]> {
  public perform(): Promise<PaymentListItem[]> {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<PaymentListItem[]>) => {
        return payload.data;
      },
    );
  }
}
