import { DEFAULT_SEGMENTS_FILTER } from '@/models/dictionary/segments.model';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import { useSegmentsStore } from '@/store/dictionary/segments.store';
import {
  pspLimitsSettingsFormRef,
  usePaymentMethodsDepositLimitsStore,
} from '@/store/ps-management/payment-methods-deposit-limits.store';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted, ref } from 'vue';
import PspLimit from '@/layouts/ps-management-page/psp-limits-settings/psp-limit/psp-limit.vue';
import { Plus, Delete } from '@element-plus/icons-vue';
import { GeoPaymentMethodDepositLimits, geoPaymentsDepositLimitDefaults } from '@/models/ps-management/limits.model';
import { cloneDeep } from 'lodash';
import { pspLimitsSettingsFormRules } from '@/hooks/ps-management/deposit-limits-validation-rules';

const icons = {
  Plus,
  Delete,
};

export default defineComponent({
  name: 'PspLimitsSettings',
  components: {
    PspLimit,
  },
  setup() {
    const paymentMethodsDepositLimitsStore = usePaymentMethodsDepositLimitsStore();
    const {
      closePspLimitsSettings,
      updateGeoPaymentMethodDepositLimits,
      getGeoPaymentMethodDepositLimits,
    } = paymentMethodsDepositLimitsStore;
    const {
      isPspLimitsSettingsSidebarVisible,
      isDepositLimitsLoading,
      currentGeoPaymentMethodId,
      isUpdatingDepositLimits,
      depositLimitsData,
      availableCurrencies,
    } = storeToRefs(paymentMethodsDepositLimitsStore);

    const currencyStore = useCurrencyListStore();
    const { isCurrencyListLoading, currencyList } = storeToRefs(currencyStore);
    const { getCurrencyList } = currencyStore;

    const segmentsStore = useSegmentsStore();
    const { getSegmentList } = segmentsStore;
    const { isSegmentListLoading } = storeToRefs(segmentsStore);

    const collapseItemNames = ref<string[]>([]);

    const isPspLimitsSettingsLoading = computed(
      () => isDepositLimitsLoading.value ||
        isCurrencyListLoading.value ||
        isSegmentListLoading.value,
    );

    onMounted(() => {
      if (!currentGeoPaymentMethodId.value) {
        return;
      }

      getCurrencyList();
      getSegmentList(DEFAULT_SEGMENTS_FILTER);
      getGeoPaymentMethodDepositLimits(currentGeoPaymentMethodId.value);
    });

    const submitPspLimitsSettings = (payload: GeoPaymentMethodDepositLimits[]): void => {
      updateGeoPaymentMethodDepositLimits({
        billingPaymentMethodSettingsId: currentGeoPaymentMethodId.value,
        depositLimitList: payload,
      })
        .then(() => {
          closePspLimitsSettings();
        });
    };

    const savePspLimitsSettings = (): void => {
      if (!pspLimitsSettingsFormRef.value) {
        submitPspLimitsSettings([]);

        return;
      }

      pspLimitsSettingsFormRef.value.validate((valid) => {
        if (!valid || !currentGeoPaymentMethodId.value) {
          return;
        }

        submitPspLimitsSettings(depositLimitsData.value[currentGeoPaymentMethodId.value].depositLimitsList);
      });
    };

    const addCurrencyItem = (): void => {
      if (!currentGeoPaymentMethodId.value) {
        return;
      }

      depositLimitsData
        .value[currentGeoPaymentMethodId.value].depositLimitsList.push(cloneDeep(geoPaymentsDepositLimitDefaults));
      const addedCurrencyIndex =
        depositLimitsData.value[currentGeoPaymentMethodId.value].depositLimitsList.length - 1;
      collapseItemNames.value.push(`item-${addedCurrencyIndex}`);
    };

    const deleteCurrencyItem = (index: number): void => {
      depositLimitsData.value[currentGeoPaymentMethodId.value].depositLimitsList.splice(index, 1);
    };

    const closeLimitSettings = (): void => {
      getGeoPaymentMethodDepositLimits(currentGeoPaymentMethodId.value);

      closePspLimitsSettings();
    };

    const limitsListData = computed<GeoPaymentMethodDepositLimits[]>(() => {
      if (!currentGeoPaymentMethodId.value) {
        return [];
      }

      return depositLimitsData.value[currentGeoPaymentMethodId.value];
    });

    return {
      isPspLimitsSettingsSidebarVisible,
      closeLimitSettings,
      pspLimitsSettingsFormRef,
      isPspLimitsSettingsLoading,
      currencyList,
      savePspLimitsSettings,
      collapseItemNames,
      icons,
      addCurrencyItem,
      isUpdatingDepositLimits,
      pspLimitsSettingsFormRules,
      availableCurrencies,
      deleteCurrencyItem,
      depositLimitsData,
      limitsListData,
      geoPaymentsDepositLimitDefaults,
      currentGeoPaymentMethodId,
    };
  },
});
