import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import {
  TransactionListItem,
  TransactionListResponseModel,
  TransactionListRequestModel,
} from '@/models/transaction/transaction-list.model';
import { clearPayloadData } from '@/utils/clear-payload-data.util';
import { formatDatesInPayload } from '@/utils/date-time.format.util';

export default class GetTransactionListRequest extends ApiResourceMethod<TransactionListResponseModel> {
  public perform(params: TransactionListRequestModel) {
    const requestData = { ...params };

    if (requestData.filterList && Object.keys(requestData.filterList).length > 0) {
      requestData.filterList = formatDatesInPayload({
        payload: { ...requestData.filterList },
        unixToString: false,
        includeTime: true,
      });
    }

    return this.performer.requestWithMapper(
      { params: clearPayloadData(requestData) },
      (payload: BaseResponse<TransactionListItem[]>) => {
        return { data: payload.data, headers: payload.headers };
      },
    );
  }
}
