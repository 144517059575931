import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "upload-image" }
const _hoisted_2 = ["accept"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_image = _resolveComponent("el-image")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_button, {
      type: "primary",
      plain: "",
      class: "upload-image__button",
      icon: _ctx.icons.Upload,
      onClick: _ctx.triggerFileInput
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Upload Image ")
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["icon", "onClick"]),
    _createElementVNode("input", {
      ref: "fileInput",
      class: "upload-image__input",
      type: "file",
      accept: _ctx.ACCEPT_EXTENSIONS,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.imageInput && _ctx.imageInput(...args)))
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_2),
    _createElementVNode("div", null, [
      (_ctx.isSelectedImage)
        ? (_openBlock(), _createBlock(_component_el_image, {
            key: 0,
            src: _ctx.selectedImagePreview,
            class: "upload-image__preview",
            fit: "contain"
          }, null, 8 /* PROPS */, ["src"]))
        : (!_ctx.isSelectedImage && _ctx.oldImageUrl)
          ? (_openBlock(), _createBlock(_component_el_image, {
              key: 1,
              src: _ctx.oldImageUrl,
              class: "upload-image__preview",
              fit: "contain"
            }, null, 8 /* PROPS */, ["src"]))
          : _createCommentVNode("v-if", true)
    ])
  ]))
}