import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { BillingPaymentMethodDepositLimit } from '@/models/ps-management/limits.model';

export default class UpdateGeoPaymentMethodDepositLimitsRequest extends ApiResourceMethod<{}> {
  public perform(geoPaymentMethodDepositLimits: BillingPaymentMethodDepositLimit): Promise<{}> {
    return this.performer.requestWithMapper(
      {
        url: `${geoPaymentMethodDepositLimits.billingPaymentMethodSettingsId}`,
        data: { ...geoPaymentMethodDepositLimits },
      },
      (response: BaseResponse<{}>) => {
        return response.data;
      },
    );
  }
}
