import { ApiResourceMethod } from '@/api/api-requests-factory';
import { FileDataResponse } from '@/models/file-data-response.model';
import { TransactionListRequestModel } from '@/models/transaction/transaction-list.model';
import { clearPayloadData } from '@/utils/clear-payload-data.util';
import { formatDatesInPayload } from '@/utils/date-time.format.util';

export default class GetTransactionsReportRequest extends ApiResourceMethod<FileDataResponse> {
  public perform(params: TransactionListRequestModel) {
    const requestData = { ...params };

    if (requestData.filterList && Object.keys(requestData.filterList).length > 0) {
      requestData.filterList = formatDatesInPayload({
        payload: { ...requestData.filterList },
      });
    }

    return this.performer.requestWithMapper(
      { params: clearPayloadData(requestData) },
    );
  }
}
