export enum WhiteLabels {
  NINECASINO = 'ninecasino',
  BRUNOCASINO = 'brunocasino',
  CRYPTOLEO = 'cryptoleo',
  BETONRED = 'betonred',
  BETONRED_NG = 'betonred-ng',
  JOKABET = 'jokabet',
  NINEWIN = 'ninewin',
  KINGHILLS = 'kinghills',
  IVYBET = 'ivybet',
  B7CASINO = 'b7casino',
}

export const WhiteLabelDomainsMapping: { [key in WhiteLabels]: string } = {
  [WhiteLabels.NINECASINO]: 'ninecasino.com',
  [WhiteLabels.BRUNOCASINO]: 'brunocasino.com',
  [WhiteLabels.CRYPTOLEO]: 'cryptoleo.com',
  [WhiteLabels.BETONRED]: 'betonred.com',
  [WhiteLabels.BETONRED_NG]: 'betonred.ng',
  [WhiteLabels.JOKABET]: 'cryptoleo.com',
  [WhiteLabels.NINEWIN]: 'ninewin.com',
  [WhiteLabels.KINGHILLS]: 'kinghills.com',
  [WhiteLabels.IVYBET]: 'ivybet.io',
  [WhiteLabels.B7CASINO]: 'b7casino.com',
};
