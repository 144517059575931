import { PaymentListItem } from '@/models/dictionary/payment-list.model';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { defineStore } from 'pinia';

interface PaymentListState {
  paymentList: PaymentListItem[];
  isPaymentListLoading: boolean;
}

export const usePaymentListStore = defineStore({
  id: 'PaymentListStore',
  state: (): PaymentListState => ({
    paymentList: [],
    isPaymentListLoading: false,
  }),
  actions: {
    setPaymentListLoading(isLoading: boolean): void {
      this.isPaymentListLoading = isLoading;
    },
    getPaymentList(): Promise<PaymentListItem[] | void> {
      if (this.paymentList.length > 0) {
        return Promise.resolve(this.paymentList);
      }

      this.setPaymentListLoading(true);

      return api.dictionary.getPaymentListRequest.perform()
        .then((data) => {
          this.paymentList = data;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return Promise.reject(error);
        })
        .finally(() => {
          this.setPaymentListLoading(false);
        });
    },
  },
});
