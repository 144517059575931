export enum SidebarDirection {
  LEFT_TO_RIGHT = 'ltr',
  RIGHT_TO_LEFT = 'rtl',
  TOP_TO_BOTTOM = 'ttb',
  BOTTOM_TO_TOP = 'btt',
}

export enum FilterComponentEvents {
  CLOSE_FILTER = 'closeFilter',
  RESET_FILTER = 'resetFilter',
  SUBMIT_FILTER = 'submitFilter',
}
