import { TransactionListItem } from '@/models/transaction/transaction-list.model';
import { defineStore, storeToRefs } from 'pinia';
import { useTransactionListFilterStore } from './transaction-list-filter.store';
import { clearObjIncludesFields } from '@/utils/form.utils';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { TransactionListSummary } from '@/models/transaction/transaction-list-summary.model';
import { useTransactionListStore } from './transaction-list.store';
import useTokenPagination from '@/hooks/use-token-pagination.hook';

type TransactionListSummaryStore = {
  isTransactionListSummaryLoading: boolean;
  transactionListSummary: TransactionListSummary | null;
}

export const useTransactionListSummaryStore = defineStore({
  id: 'TransactionListSummaryStore',
  state: (): TransactionListSummaryStore => ({
    isTransactionListSummaryLoading: false,
    transactionListSummary: null,
  }),
  getters: {
    transactionListSummaryData(state: TransactionListSummaryStore): TransactionListSummary[] | [] {
      return this.transactionListSummary ? [this.transactionListSummary] : [];
    },
  },
  actions: {
    setTransactionListSummaryLoading(isLoading: boolean): void {
      this.isTransactionListSummaryLoading = isLoading;
    },
    getTransactionListSummary(): Promise<TransactionListItem[] | void> {
      this.setTransactionListSummaryLoading(true);

      const { submittedFilterFormData } = storeToRefs(useTransactionListFilterStore());
      const { limit, sort } = storeToRefs(useTransactionListStore());

      const {
        prevPageToken,
        nextPageToken,
      } = useTokenPagination();

      const requestPayload = {
        limit: limit.value,
        prevPageToken: prevPageToken.value,
        nextPageToken: nextPageToken.value,
        filterList: clearObjIncludesFields(submittedFilterFormData.value),
        sort: sort.value,
      };

      return api.transactions.getTransactionListSummaryRequest.perform(requestPayload)
        .then((data) => {
          this.transactionListSummary = data;
        })
        .catch((error) => {
          ElNotification({
            title: 'Error',
            message: error,
            type: 'error',
          });
        })
        .finally(() => {
          this.setTransactionListSummaryLoading(false);
        });
    },
  },
});
