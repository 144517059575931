import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_select_v2 = _resolveComponent("el-select-v2")!

  return (_openBlock(), _createBlock(_component_el_select_v2, {
    modelValue: _ctx.segmentListModelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.segmentListModelValue) = $event)),
    multiple: "",
    filterable: "",
    clearable: "",
    remote: "",
    "collapse-tags": "",
    "collapse-tags-tooltip": "",
    "remote-method": _ctx.autocompleteSearch,
    loading: _ctx.isAutocompleteSearchLoading,
    options: _ctx.autocompleteSearchResult,
    "popper-class": "segments-autocomplete-multiselect__popper",
    "value-key": "name",
    placeholder: "Select segment",
    "no-data-text": "At least 3 symbols",
    onBlur: _ctx.clearAutocompleteSearchResult,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emit('update:segmentList', _ctx.segmentListModelValue.value)))
  }, {
    label: _withCtx(({ value }) => [
      _createVNode(_component_el_tooltip, {
        effect: "light",
        content: value.name
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(value.name), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["content"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "remote-method", "loading", "options", "onBlur"]))
}