import { defineComponent, ref } from 'vue';
import { Upload } from '@element-plus/icons-vue';
import { useUploadImageStore } from '@/store/upload-image.store';
import { storeToRefs } from 'pinia';
import { ACCEPT_EXTENSIONS } from '@/models/upload-image.model';

const icons = {
  Upload,
};

export default defineComponent({
  name: 'UploadImageComponent',
  setup() {
    const uploadImageStore = useUploadImageStore();
    const { imageInput } = uploadImageStore;
    const { selectedImage, selectedImagePreview, isSelectedImage, oldImageUrl } = storeToRefs(uploadImageStore);

    const fileInput = ref<HTMLInputElement | null>(null);

    const triggerFileInput = (): void => {
      fileInput.value?.click();
    };

    return {
      ACCEPT_EXTENSIONS,
      selectedImagePreview,
      imageInput,
      triggerFileInput,
      fileInput,
      icons,
      selectedImage,
      isSelectedImage,
      oldImageUrl,
    };
  },
});
