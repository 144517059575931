import { PropType, defineComponent } from 'vue';
import { FilterComponentEvents, SidebarDirection } from '@/models/app-internal/in-app-events.model';
import { Close as CloseIcon } from '@element-plus/icons-vue';

export default defineComponent({
  name: 'ListFilterSidebar',
  components: { CloseIcon },
  props: {
    direction: {
      type: String as PropType<SidebarDirection>,
      default: SidebarDirection.RIGHT_TO_LEFT,
    },
    title: {
      type: String,
      default: 'Sidebar title',
    },
    resetButtonTitle: {
      type: String,
      default: 'Reset',
    },
    submitButtonTitle: {
      type: String,
      default: 'Apply',
    },
    isFilterLoading: {
      type: Boolean,
      required: true,
    },
    isFilterActive: {
      type: Boolean,
      required: true,
    },
    isFilterVisible: {
      type: Boolean,
      required: true,
    },
  },
  emits: [
    FilterComponentEvents.CLOSE_FILTER,
    FilterComponentEvents.RESET_FILTER,
    FilterComponentEvents.SUBMIT_FILTER,
  ],
  setup(_props, { emit }) {
    const closeFilter = () => emit(FilterComponentEvents.CLOSE_FILTER);
    const resetFilter = () => emit(FilterComponentEvents.RESET_FILTER);
    const submitFilter = (event: Event) => {
      event.preventDefault();
      emit(FilterComponentEvents.SUBMIT_FILTER);
    };

    return {
      closeFilter,
      resetFilter,
      submitFilter,
    };
  },
});
