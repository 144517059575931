import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { UploadImageModel } from '@/models/upload-image.model';

export default class UploadImageRequest extends ApiResourceMethod<UploadImageModel> {
  public perform(data): Promise<UploadImageModel> {
    return this.performer.requestWithMapper(
      { url: 'file', headers: { 'Content-Type': 'image/*' }, data },
      (payload: BaseResponse<UploadImageModel>) => {
        return payload.data;
      },
    );
  }
}
