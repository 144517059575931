import { PAYMENTS_SECTIONS_ROUTES } from '@/models/app-internal/permissions.model';
import { RouteComponent, RouteRecordRaw } from 'vue-router';

export const transactionsRoutes: RouteRecordRaw[] = [
  {
    name: PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS,
    path: `/${PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS}`,
    component: async(): Promise<RouteComponent> =>
      await import('@/layouts/transactions-page/transactions-page.vue'),
  },
];
