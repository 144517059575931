import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { CountryList } from '@/models/dictionary/country-list.model';

export default class AddPaymentMethodCountriesRequest extends ApiResourceMethod<{}> {
  public perform(countryList: CountryList): Promise<{}> {
    return this.performer.requestWithMapper(
      {
        data: {
          paymentMethodGeoList: countryList,
        },
      },
      (payload: BaseResponse<{}>) => {
        return { data: payload.data };
      },
    );
  }
}
