const isValidValue = (value: any): boolean => value !== null && value !== '' && value !== undefined;

/* eslint-disable no-param-reassign */
export const clearObj = (obj) => {
  return Object.entries(obj).reduce((result, [key, value]) => {
    // @ts-ignore
    const isDate = isValidValue(value) && !!value.getDate;

    if (Array.isArray(value)) {
      const cleanedArray = value.filter(item => {
        return Object.values(item).some(subValue => isValidValue(subValue));
      });

      if (cleanedArray.length > 0) {
        // eslint-disable-next-line no-param-reassign
        result[key] = cleanedArray;
      }
    } else if (value && typeof value === 'object' && !Array.isArray(value) && !isDate) {
      const clearedValue = clearObj(value);

      if (Object.keys(clearedValue).length > 0) {
        // eslint-disable-next-line no-param-reassign
        result[key] = clearedValue;
      }
    } else if (isValidValue(value)) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
    }

    return result;
  }, {});
};

export const clearObjIncludesFields = (obj) => {
  const requiredFields = ['isActive'];

  return Object.entries(obj).reduce((result, [key, value]) => {
    if (Array.isArray(value) && value.length === 0) {
      return result;
    }

    if (isValidValue(value) && (!requiredFields.includes(key) ||
      (requiredFields.find(field => field === key) && value !== false))) {
      // eslint-disable-next-line no-param-reassign
      result[key] = value;
    }

    return result;
  }, {});
};
