import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import {
  GeoPaymentMethodList,
  GetGeoPaymentMethodListRequestPayload,
} from '@/models/ps-management/ps-management.model';

export default class GetGeoPaymentMethodListRequest extends ApiResourceMethod<GeoPaymentMethodList> {
  public perform(params: GetGeoPaymentMethodListRequestPayload): Promise<GeoPaymentMethodList> {
    return this.performer.requestWithMapper(
      { params },
      (payload: BaseResponse<GeoPaymentMethodList>) => {
        return payload.data;
      },
    );
  }
}
