import { RouteComponent, RouteRecordRaw } from 'vue-router';
import { psManagementRoutes } from './ps-management/ps-management.routes';
import { transactionsRoutes } from './transactions/transactions.routes';

export const routes: RouteRecordRaw[] = [
  {
    name: 'home',
    path: '',
    component: async (): Promise<RouteComponent> => await import('@/layouts/home-page/home-page.vue'),
  },
  {
    name: 'permissions',
    path: '/permissions',
    component: async (): Promise<RouteComponent> => await import('@/layouts/permissions-page/permissions-page.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'home' },
  },
  ...psManagementRoutes,
  ...transactionsRoutes,
];
