import { PaymentSystemListItem } from '@/models/dictionary/payment-system-list.model';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { defineStore } from 'pinia';

interface PaymentSystemListState {
  paymentSystemList: PaymentSystemListItem[];
  isPaymentSystemListLoading: boolean;
}

export const usePaymentSystemListStore = defineStore({
  id: 'PaymentSystemListStore',
  state: (): PaymentSystemListState => ({
    paymentSystemList: [],
    isPaymentSystemListLoading: false,
  }),
  actions: {
    setPaymentSystemListLoading(isLoading: boolean): void {
      this.isPaymentSystemListLoading = isLoading;
    },
    getPaymentSystemList(): Promise<PaymentSystemListItem[] | void> {
      if (this.paymentSystemList.length > 0) {
        return Promise.resolve(this.paymentSystemList);
      }

      this.setPaymentSystemListLoading(true);

      return api.dictionary.getPaymentSystemListRequest.perform()
        .then((data) => {
          this.paymentSystemList = data;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return Promise.reject(error);
        })
        .finally(() => {
          this.setPaymentSystemListLoading(false);
        });
    },
  },
});
