import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';

export default class DeleteGlobalPaymentMethodRequest extends ApiResourceMethod<{}> {
  public perform(paymentMethodId: string): Promise<{}> {
    return this.performer.requestWithMapper(
      { params: { paymentMethodId } },
      (payload: BaseResponse<{}>) => {
        return payload.data;
      },
    );
  }
}
