export type UploadImageModel = {
  fileId: string;
  filePath: string;
}

export type CurrentUploadDataModel = {
  uploadedImageFilePath: string;
  uploadedImageFileId: string;
}

export type FilePathResponsePayload = {
  filePath: string;
}

export const ACCEPT_EXTENSIONS = 'image/jpeg, image/webp, image/svg+xml, image/png';

export const DEFAULT_CURRENT_UPLOAD_IMAGE_DATA: CurrentUploadDataModel = {
  uploadedImageFilePath: '',
  uploadedImageFileId: '',
};
