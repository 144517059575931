import {
  PAYMENTS_SECTIONS_ROUTES,
  PS_MANAGEMENT_PERMISSIONS,
  PaymentsPermissions,
  TRANSACTIONS_PERMISSIONS,
} from '@/models/app-internal/permissions.model';
import { defineStore } from 'pinia';

interface PermissionsStore {
  paymentsPermissions: PaymentsPermissions;
}

export const usePermissionsStore = defineStore({
  id: 'permissions',
  state: (): PermissionsStore => ({
    paymentsPermissions: {
      [PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT]: {
        // GEO PAYMENT SYSTEMS SETTINGS PERMISSIONS
        [PS_MANAGEMENT_PERMISSIONS.GET_GEO_PAYMENT_METHOD_LIST]: false,
        [PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD_LIST]: false,
        [PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW]: false,
        [PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD]: false,
        [PS_MANAGEMENT_PERMISSIONS.CREATE_GEO_PAYMENT_METHOD]: false,
        [PS_MANAGEMENT_PERMISSIONS.DELETE_GEO_PAYMENT_METHOD]: false,
        // GEO PAYMENT METHODS DEPOSIT LIMITS PERMISSIONS
        [PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS]: false,
        [PS_MANAGEMENT_PERMISSIONS.GET_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS]: false,
        [PS_MANAGEMENT_PERMISSIONS.GET_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS_LIST]: false,

        // GEO LIST PERMISSIONS
        [PS_MANAGEMENT_PERMISSIONS.GET_PAYMENT_METHOD_GEO_LIST]: false,
        [PS_MANAGEMENT_PERMISSIONS.ADD_PAYMENT_METHOD_GEO]: false,

        // GLOBAL PAYMENT SYSTEMS SETTINGS PERMISSIONS
        [PS_MANAGEMENT_PERMISSIONS.GET_GLOBAL_PAYMENT_METHOD_LIST]: false,
        [PS_MANAGEMENT_PERMISSIONS.GET_GLOBAL_PAYMENT_METHOD]: false,
        [PS_MANAGEMENT_PERMISSIONS.CREATE_GLOBAL_PAYMENT_METHOD]: false,
        [PS_MANAGEMENT_PERMISSIONS.DELETE_GLOBAL_PAYMENT_METHOD]: false,
        [PS_MANAGEMENT_PERMISSIONS.UPDATE_GLOBAL_PAYMENT_METHOD]: false,
        [PS_MANAGEMENT_PERMISSIONS.UPDATE_GLOBAL_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW]: false,
      },
      [PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS]: {
        [TRANSACTIONS_PERMISSIONS.TRANSACTION_MODERATE]: false,
        [TRANSACTIONS_PERMISSIONS.GET_PAYMENT_LIST]: false,
        [TRANSACTIONS_PERMISSIONS.GET_PAYMENT_LIST_TOTAL]: false,
        [TRANSACTIONS_PERMISSIONS.GET_PAYMENT_LIST_CSV]: false,
      },
    },
  }),
  getters: {
    psManagementPermissions(state) {
      return state.paymentsPermissions[PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT];
    },
    transactionsPermissions(state) {
      return state.paymentsPermissions[PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS];
    },
    isTransactionsDownloadCvsAllowed(): boolean {
      return this.transactionsPermissions[TRANSACTIONS_PERMISSIONS.GET_PAYMENT_LIST_CSV];
    },
  },
  actions: {
    setPermissions(permissions: PaymentsPermissions) {
      this.paymentsPermissions = permissions;
    },
    checkPermission(permission: string) {
      const actualSections = Object.keys(this.paymentsPermissions);

      return actualSections.some((section) => {
        return this.paymentsPermissions[section as PAYMENTS_SECTIONS_ROUTES][permission];
      });
    },
  },
});
