import { TagComponentTypes } from '@/models/app-common.model';

export type TransactionListSummary = {
  totalAmount: string;
  totalAmountEur: string;
  moderationCount: string;
  moderationProcessCount: string;
  pendingCount: string;
  cancelledCount: string;
  paidCount: string;
  processedCount: string;
  totalTransactionCount: string;
  holdCount: string;
  uncompletedCount: string;
  kycVerificationCount: string;
  initiatedCount: string;
}

export enum TransactionListSummaryColumnProperties {
  TOTAL_TRANSACTION_COUNT = 'totalTransactionCount',
  PENDING_COUNT = 'pendingCount',
  KYC_VERIFICATION_COUNT = 'kycVerificationCount',
  PAID_COUNT = 'paidCount',
  HOLD_COUNT = 'holdCount',
  PROCESSED_COUNT = 'processedCount',
  CANCELLED_COUNT = 'cancelledCount',
  INITIATED_COUNT = 'initiatedCount',
  UNCOMPLETED_COUNT = 'uncompletedCount',
  TOTAL_AMOUNT = 'totalAmount',
  TOTAL_AMOUNT_EUR = 'totalAmountEur',
}

export enum TransactionListSummaryColumnLabels {
  TOTAL_TRANSACTION_COUNT = 'Total',
  PENDING_COUNT = 'Pending',
  KYC_VERIFICATION_COUNT = 'Waiting KYC result',
  PAID_COUNT = 'Paid',
  HOLD_COUNT = 'Hold',
  PROCESSED_COUNT = 'Processed',
  CANCELLED_COUNT = 'Cancelled',
  INITIATED_COUNT = 'Initiated',
  UNCOMPLETED_COUNT = 'Uncompleted',
  TOTAL_AMOUNT = 'Sum',
  TOTAL_AMOUNT_EUR = 'Equivalent in EUR',
}

export const transactionListSummaryColumnTagTypes = {
  [TransactionListSummaryColumnProperties.PAID_COUNT]: TagComponentTypes.SUCCESS,
  [TransactionListSummaryColumnProperties.PENDING_COUNT]: TagComponentTypes.WARNING,
  [TransactionListSummaryColumnProperties.CANCELLED_COUNT]: TagComponentTypes.INFO,
  [TransactionListSummaryColumnProperties.PROCESSED_COUNT]: TagComponentTypes.SUCCESS,
  [TransactionListSummaryColumnProperties.HOLD_COUNT]: TagComponentTypes.WARNING,
  [TransactionListSummaryColumnProperties.INITIATED_COUNT]: TagComponentTypes.SUCCESS,
  [TransactionListSummaryColumnProperties.UNCOMPLETED_COUNT]: TagComponentTypes.INFO,
  [TransactionListSummaryColumnProperties.KYC_VERIFICATION_COUNT]: TagComponentTypes.SUCCESS,
};

export const transactionListSummaryColumnLabels = {
  [TransactionListSummaryColumnProperties.TOTAL_TRANSACTION_COUNT]:
  TransactionListSummaryColumnLabels.TOTAL_TRANSACTION_COUNT,
  [TransactionListSummaryColumnProperties.PENDING_COUNT]: TransactionListSummaryColumnLabels.PENDING_COUNT,
  [TransactionListSummaryColumnProperties.KYC_VERIFICATION_COUNT]:
  TransactionListSummaryColumnLabels.KYC_VERIFICATION_COUNT,
  [TransactionListSummaryColumnProperties.PAID_COUNT]: TransactionListSummaryColumnLabels.PAID_COUNT,
  [TransactionListSummaryColumnProperties.HOLD_COUNT]: TransactionListSummaryColumnLabels.HOLD_COUNT,
  [TransactionListSummaryColumnProperties.PROCESSED_COUNT]: TransactionListSummaryColumnLabels.PROCESSED_COUNT,
  [TransactionListSummaryColumnProperties.CANCELLED_COUNT]: TransactionListSummaryColumnLabels.CANCELLED_COUNT,
  [TransactionListSummaryColumnProperties.INITIATED_COUNT]: TransactionListSummaryColumnLabels.INITIATED_COUNT,
  [TransactionListSummaryColumnProperties.UNCOMPLETED_COUNT]: TransactionListSummaryColumnLabels.UNCOMPLETED_COUNT,
  [TransactionListSummaryColumnProperties.TOTAL_AMOUNT]: TransactionListSummaryColumnLabels.TOTAL_AMOUNT,
  [TransactionListSummaryColumnProperties.TOTAL_AMOUNT_EUR]: TransactionListSummaryColumnLabels.TOTAL_AMOUNT_EUR,
};

export type TransactionListSummaryColumnItem = {
  label: string;
  value: string;
  tagType?: string;
};

export const transactionListSummaryColumns: TransactionListSummaryColumnItem[] = [
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.TOTAL_TRANSACTION_COUNT],
    value: TransactionListSummaryColumnProperties.TOTAL_TRANSACTION_COUNT,
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.PENDING_COUNT],
    value: TransactionListSummaryColumnProperties.PENDING_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.PENDING_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.KYC_VERIFICATION_COUNT],
    value: TransactionListSummaryColumnProperties.KYC_VERIFICATION_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.KYC_VERIFICATION_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.PAID_COUNT],
    value: TransactionListSummaryColumnProperties.PAID_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.PAID_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.HOLD_COUNT],
    value: TransactionListSummaryColumnProperties.HOLD_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.HOLD_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.PROCESSED_COUNT],
    value: TransactionListSummaryColumnProperties.PROCESSED_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.PROCESSED_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.CANCELLED_COUNT],
    value: TransactionListSummaryColumnProperties.CANCELLED_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.CANCELLED_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.INITIATED_COUNT],
    value: TransactionListSummaryColumnProperties.INITIATED_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.INITIATED_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.UNCOMPLETED_COUNT],
    value: TransactionListSummaryColumnProperties.UNCOMPLETED_COUNT,
    tagType: transactionListSummaryColumnTagTypes[TransactionListSummaryColumnProperties.UNCOMPLETED_COUNT],
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.TOTAL_AMOUNT],
    value: TransactionListSummaryColumnProperties.TOTAL_AMOUNT,
  },
  {
    label: transactionListSummaryColumnLabels[TransactionListSummaryColumnProperties.TOTAL_AMOUNT_EUR],
    value: TransactionListSummaryColumnProperties.TOTAL_AMOUNT_EUR,
  },
];
