import { reactive } from 'vue';
import { TransactionListItem } from '@/models/transaction/transaction-list.model';

export enum EmitterEvent {
  OPEN_PLAYER_PROFILE = 'open-player-profile',
}

type EventPayload = {
  [EmitterEvent.OPEN_PLAYER_PROFILE]: { playerId: TransactionListItem['playerId'] };
};

interface Emitter {
  toRoot: <T extends EmitterEvent>(name: T, payload: EventPayload[T]) => void;
}

interface UseEmitterHook {
  setupRootEmitter: (fn: Emitter['toRoot']) => unknown;
  emitter: Emitter;
  openPlayerProfile: (playerId: string) => void;
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const emitter = reactive(<Emitter>{
  toRoot: () => ({}),
});

const openPlayerProfile = (playerId: string) => {
  emitter.toRoot(EmitterEvent.OPEN_PLAYER_PROFILE, { playerId });
};

export default function useEmitter(): UseEmitterHook {
  return {
    setupRootEmitter: (fn) => {
      emitter.toRoot = fn;
    },
    emitter,
    openPlayerProfile,
  };
}
