import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "column-filter__label" }
const _hoisted_2 = { class: "column-filter__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    placement: "bottom",
    width: "max-content",
    trigger: "click"
  }, {
    reference: _withCtx(() => [
      _createVNode(_component_el_button, {
        circle: "",
        icon: _ctx.Setting,
        loading: _ctx.isLoading,
        disabled: _ctx.isDisabled
      }, null, 8 /* PROPS */, ["icon", "loading", "disabled"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_checkbox_group, {
          modelValue: _ctx.checkedColumns,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkedColumns) = $event))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_draggable, {
              modelValue: _ctx.columns,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.columns) = $event)),
              "item-key": "label",
              class: "column-filter__checkboxes"
            }, {
              item: _withCtx(({ element }) => [
                _createVNode(_component_el_checkbox, {
                  size: "small",
                  label: element.value,
                  value: element.value,
                  disabled: element.value === 'actions'
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_el_icon, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Grid)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createTextVNode(" " + _toDisplayString(element.label), 1 /* TEXT */)
                    ])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value", "disabled"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_button, {
            size: "small",
            plain: "",
            disabled: _ctx.isLoading,
            onClick: _ctx.resetColumns
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Reset ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"]),
          _createVNode(_component_el_button, {
            type: "primary",
            size: "small",
            plain: "",
            disabled: _ctx.isLoading,
            onClick: _ctx.updateColumns
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Apply ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled", "onClick"])
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}