import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "limit-currency-configuration" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "limit-currency-configuration__input-block" }
const _hoisted_4 = { class: "limit-currency-configuration__input-block" }
const _hoisted_5 = { class: "limit-currency-configuration__input-block limit-currency-configuration__input-block_borderless" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.globalPaymentMethodsSettingsFormData.depositLimitList.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_form, {
            ref: "globalPaymentMethodsCurrencyFormRef",
            model: _ctx.globalPaymentMethodsSettingsFormData
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.globalPaymentMethodsSettingsFormData.depositLimitList, (depositLimit, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "limit-currency-configuration__currency-item"
                }, [
                  _createVNode(_component_el_form_item, { label: "Deposit limit" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_el_input_number, {
                          modelValue: depositLimit.min,
                          "onUpdate:modelValue": ($event: any) => ((depositLimit.min) = $event),
                          placeholder: "Min",
                          controls: false,
                          class: "limit-currency-configuration__field"
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_component_el_input_number, {
                          modelValue: depositLimit.max,
                          "onUpdate:modelValue": ($event: any) => ((depositLimit.max) = $event),
                          placeholder: "Max",
                          controls: false,
                          class: "limit-currency-configuration__field"
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode(_component_el_form_item, { label: "Cash out limit" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_el_input_number, {
                          modelValue: _ctx.globalPaymentMethodsSettingsFormData.withdrawLimitList[index].min,
                          "onUpdate:modelValue": ($event: any) => ((_ctx.globalPaymentMethodsSettingsFormData.withdrawLimitList[index].min) = $event),
                          placeholder: "Min",
                          controls: false,
                          class: "limit-currency-configuration__field"
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]),
                        _createVNode(_component_el_input_number, {
                          modelValue: _ctx.globalPaymentMethodsSettingsFormData.withdrawLimitList[index].max,
                          "onUpdate:modelValue": ($event: any) => ((_ctx.globalPaymentMethodsSettingsFormData.withdrawLimitList[index].max) = $event),
                          placeholder: "Max",
                          controls: false,
                          class: "limit-currency-configuration__field"
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                      ])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createVNode(_component_el_form_item, {
                    label: "Currency",
                    prop: 'depositLimitList.' + index + '.currency',
                    rules: _ctx.globalPaymentMethodsFormCurrencyRules,
                    class: "psp-limits-settings__field"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_el_select, {
                          modelValue: depositLimit.currency,
                          "onUpdate:modelValue": ($event: any) => ((depositLimit.currency) = $event),
                          placeholder: "Select currency",
                          teleported: false,
                          onChange: ($event: any) => (_ctx.updateCurrencyByIndex($event, index))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableCurrencyList, (currency) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: currency.code,
                                label: currency.code,
                                value: currency.code
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(currency.code), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "onChange"]),
                        _createVNode(_component_el_button, {
                          type: "danger",
                          icon: _ctx.icons.Delete,
                          circle: "",
                          plain: "",
                          onClick: ($event: any) => (_ctx.deleteCurrencyItem(index))
                        }, null, 8 /* PROPS */, ["icon", "onClick"])
                      ])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "rules"])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["model"])
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_el_button, {
      type: "primary",
      icon: _ctx.icons.Plus,
      plain: "",
      onClick: _ctx.addCurrencyItem
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Add currency ")
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["icon", "onClick"])
  ]))
}