import { useCountryListStore } from '@/store/dictionary/countries.store';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import { usePaymentOperationListStore } from '@/store/dictionary/payment-operation-list.store';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
import {
  cancelledByFilterOptions,
  transactionStatusFilterOptions,
  moderationStatusFilterOptions,
  processingTypeFilterOptions,
  firstDepositFilterOptions,
} from '@/models/transaction/transaction-list-filter.model';
import { usePaymentSystemListStore } from '@/store/dictionary/payment-system-list.store';
import { usePaymentListStore } from '@/store/dictionary/payment-list.store';
import { usePaymentAccountListStore } from '@/store/dictionary/payment-account-list.store';
import { useBillingProviderListStore } from '@/store/dictionary/billing-provider-list.store';
import { useTransactionOperatingSystemListStore } from '@/store/dictionary/transaction-operating-system-list-store';
import { useTransactionListFilterStore } from '@/store/transaction/transaction-list-filter.store';
import { useAdminsStore } from '@/store/dictionary/admins.store';

export default defineComponent({
  name: 'TransactionsFilter',
  setup() {
    const transactionListFilterStore = useTransactionListFilterStore();
    const { filterFormData } = storeToRefs(transactionListFilterStore);

    const { isPaymentOperationListLoading, paymentOperationList } = storeToRefs(usePaymentOperationListStore());
    const { isCountryListLoading, countryList } = storeToRefs(useCountryListStore());
    const { isCurrencyListLoading, currencyList } = storeToRefs(useCurrencyListStore());
    const { isPaymentListLoading, paymentList } = storeToRefs(usePaymentListStore());
    const { isPaymentSystemListLoading, paymentSystemList } = storeToRefs(usePaymentSystemListStore());
    const { isPaymentAccountListLoading, paymentAccountList } = storeToRefs(usePaymentAccountListStore());
    const { isBillingProviderListLoading, billingProviderList } = storeToRefs(useBillingProviderListStore());
    const {
      isTransactionOperatingSystemListLoading,
      transactionOperatingSystemList,
    } = storeToRefs(useTransactionOperatingSystemListStore());

    const adminsStore = useAdminsStore();
    const { adminEmailList, isAdminEmailListLoading } = storeToRefs(adminsStore);
    const { getAdminEmailList, clearAdminEmailList } = adminsStore;

    const getAdminEmails = (emailQuery: string) => {
      getAdminEmailList({
        filterList: {
          adminEmail: emailQuery,
        },
      });
    };

    return {
      filterFormData,
      isPaymentOperationListLoading,
      paymentOperationList,
      isCountryListLoading,
      countryList,
      isCurrencyListLoading,
      currencyList,
      cancelledByFilterOptions,
      transactionStatusFilterOptions,
      moderationStatusFilterOptions,
      processingTypeFilterOptions,
      isPaymentSystemListLoading,
      paymentSystemList,
      isPaymentListLoading,
      paymentList,
      isPaymentAccountListLoading,
      paymentAccountList,
      isBillingProviderListLoading,
      billingProviderList,
      firstDepositFilterOptions,
      isTransactionOperatingSystemListLoading,
      transactionOperatingSystemList,
      getAdminEmails,
      clearAdminEmailList,
      isAdminEmailListLoading,
      adminEmailList,
    };
  },
});
