import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { GlobalPaymentMethodPartialUpdatePayload } from '@/models/ps-management/ps-management.model';

export default class PartialUpdateGlobalPaymentMethodRequest
  extends ApiResourceMethod<{}> {
  public perform(payload: GlobalPaymentMethodPartialUpdatePayload): Promise<{}> {
    return this.performer.requestWithMapper(
      {
        url: `${payload.billingPaymentMethodId}/switchOperation`,
        data: payload.data,
      },
      (response: BaseResponse<{}>) => {
        return response.data;
      },
    );
  }
}
