import { useGlobalPsManagementStore } from '@/store/ps-management/global-payment-methods.store';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
import { EditPen, QuestionFilled } from '@element-plus/icons-vue';
import {
  GlobalPaymentMethodColumnProperties,
  GlobalPaymentMethodItem,
  GlobalPaymentMethodPartialUpdateParameterKeyModel,
  GlobalPaymentMethodPartialUpdateParams,
  globalPaymentMethodColumnsList,
} from '@/models/ps-management/ps-management.model';
import PsLimitsDetails from '@/layouts/ps-management-page/ps-limits-details/ps-limits-details.vue';
import { usePermissionsStore } from '@/store/permissions.store';
import { PS_MANAGEMENT_PERMISSIONS } from '@/models/app-internal/permissions.model';
import { ElMessageBox } from 'element-plus';
import { usePaymentTypeListStore } from '@/store/dictionary/payment-type-list.store';

const icons = {
  EditPen,
  QuestionFilled,
};

export default defineComponent({
  name: 'GlobalPaymentMethodsList',
  components: {
    PsLimitsDetails,
  },
  setup() {
    const globalPsManagementStore = useGlobalPsManagementStore();
    const {
      partialUpdateGlobalPaymentMethod,
      openGeneralPaymentMethodSettings,
    } = globalPsManagementStore;
    const {
      globalPaymentMethodList,
      isGlobalPaymentMethodListLoading,
    } = storeToRefs(globalPsManagementStore);

    const updatePaymentMethodSwitch = (payload: GlobalPaymentMethodPartialUpdateParams) => {
      partialUpdateGlobalPaymentMethod(payload);
    };

    const { psManagementPermissions } = storeToRefs(usePermissionsStore());

    const { getPaymentTypeLabel } = usePaymentTypeListStore();

    const switchPaymentMethod = (
      currentRow: GlobalPaymentMethodItem,
      currentColumn: GlobalPaymentMethodPartialUpdateParameterKeyModel,
    ) => {
      ElMessageBox.confirm(
        'Are you sure you want to toggle the PS for the casino?',
        'Warning',
        {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        },
      )
        .then(() => {
          updatePaymentMethodSwitch({
            parameterKey: currentColumn,
            billingPaymentMethodId: currentRow.billingPaymentMethodId,
            newValue: currentRow[currentColumn],
          });
        })
        .catch(() => {
          const globalPaymentMethod = globalPaymentMethodList.value
            .find((globalPaymentMethod) =>
              globalPaymentMethod.billingPaymentMethodId === currentRow.billingPaymentMethodId);

          if (!globalPaymentMethod) {
            return;
          }

          globalPaymentMethod[currentColumn] = !currentRow[currentColumn];
        });
    };

    const isUpdateGlobalPaymentMethodAllowed =
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GLOBAL_PAYMENT_METHOD];
    const isPartialUpdateGlobalPaymentMethodAllowed =
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GLOBAL_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW];

    return {
      icons,
      isGlobalPaymentMethodListLoading,
      globalPaymentMethodList,
      globalPaymentMethodColumnsList,
      GlobalPaymentMethodColumnProperties,
      updatePaymentMethodSwitch,
      openGeneralPaymentMethodSettings,
      isUpdateGlobalPaymentMethodAllowed,
      isPartialUpdateGlobalPaymentMethodAllowed,
      switchPaymentMethod,
      getPaymentTypeLabel,
    };
  },
});
