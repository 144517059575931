import { defineComponent } from 'vue';
import { Plus, Delete } from '@element-plus/icons-vue';
import { storeToRefs } from 'pinia';
import { DEFAULT_CURRENCY_ITEM } from '@/models/ps-management/ps-management.model';
import { useGeoPsManagementStore } from '@/store/ps-management/geo-payment-methods.store';

const icons = {
  Plus,
  Delete,
};

export default defineComponent({
  name: 'GeoLimitCurrencyConfiguration',
  setup() {
    const {
      geoPaymentMethodsSettingsFormData,
      isWithdrawColumnsDataVisible,
      isDepositColumnsDataVisible,
      availableDepositCurrencyList,
      availableCashoutCurrencyList,
    } = storeToRefs(useGeoPsManagementStore());

    const addCurrencyDepositLimitItem = (): void => {
      geoPaymentMethodsSettingsFormData.value.depositLimitList.push({ ...DEFAULT_CURRENCY_ITEM });
    };

    const deleteCurrencyDepositLimitItem = (index: number): void => {
      geoPaymentMethodsSettingsFormData.value.depositLimitList.splice(index, 1);
    };

    const addCurrencyWithdrawLimitItem = (): void => {
      geoPaymentMethodsSettingsFormData.value.withdrawLimitList.push({ ...DEFAULT_CURRENCY_ITEM });
    };

    const deleteCurrencyWithdrawLimitItem = (index: number): void => {
      geoPaymentMethodsSettingsFormData.value.withdrawLimitList.splice(index, 1);
    };

    return {
      icons,
      addCurrencyDepositLimitItem,
      geoPaymentMethodsSettingsFormData,
      deleteCurrencyDepositLimitItem,
      addCurrencyWithdrawLimitItem,
      deleteCurrencyWithdrawLimitItem,
      isWithdrawColumnsDataVisible,
      isDepositColumnsDataVisible,
      availableDepositCurrencyList,
      availableCashoutCurrencyList,
    };
  },
});
