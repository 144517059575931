import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { CreateGlobalPaymentMethodData } from '@/models/ps-management/ps-management.model';

export default class CreateGlobalPaymentMethodRequest extends ApiResourceMethod<{}> {
  public perform(params: CreateGlobalPaymentMethodData): Promise<{}> {
    return this.performer.requestWithMapper(
      { data: params },
      (payload: BaseResponse<{}>) => {
        return {
          data: payload.data,
          headers: payload.headers,
        };
      },
    );
  }
}
