import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isAddCountriesDialogVisible,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isAddCountriesDialogVisible) = $event)),
    title: "Add counties",
    "close-on-press-escape": "",
    width: "25%",
    center: "",
    onClose: _ctx.closeAddCountriesDialog
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        disabled: _ctx.isCountryListLoading,
        onClick: _ctx.closeAddCountriesDialog
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Cancel ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"]),
      _createVNode(_component_el_button, {
        type: "primary",
        disabled: _ctx.isCountryListLoading || !_ctx.selectedCountryList.length,
        loading: _ctx.isCountryListLoading,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addCountries(_ctx.selectedCountryList)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "loading"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        disabled: _ctx.isCountryListLoading,
        onSubmit: _withModifiers(_ctx.addCountries, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.selectedCountryList,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCountryList) = $event)),
            loading: _ctx.isCountryListLoading,
            placeholder: "Select countries",
            clearable: "",
            multiple: "",
            "value-key": "code",
            onOpen: _ctx.getCountryList
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableNewCountryList, (country) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: country.code,
                  label: country.name,
                  value: country
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading", "onOpen"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onSubmit"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onClose"]))
}