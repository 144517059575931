import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "psp-limit__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_SegmentsAutocompleteMultiselect = _resolveComponent("SegmentsAutocompleteMultiselect")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_card, { shadow: "hover" }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.depositLimitsData[_ctx.currentGeoPaymentMethodId].depositLimitsList[_ctx.limitIndex][_ctx.limitTypeKey], (pspLimit, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: pspLimit.id,
          class: "psp-limit"
        }, [
          _createVNode(_component_el_form, { model: pspLimit }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, {
                shadow: "never",
                class: "psp-limit__card"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_switch, {
                    modelValue: pspLimit.limitType,
                    "onUpdate:modelValue": ($event: any) => ((pspLimit.limitType) = $event),
                    disabled: !!pspLimit.id,
                    "active-text": "Sum of dep",
                    "inactive-text": "Num of dep",
                    "active-value": _ctx.LimitType.AMOUNT,
                    "inactive-value": _ctx.LimitType.NUMBER,
                    size: "small"
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "active-value", "inactive-value"]),
                  _createVNode(_component_el_form_item, {
                    label: "Limit",
                    prop: "limitValue",
                    rules: _ctx.pspLimitsSettingsFormRules.limitValue
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: pspLimit.limitValue,
                        "onUpdate:modelValue": ($event: any) => ((pspLimit.limitValue) = $event),
                        type: "number",
                        placeholder: "Enter limit",
                        controls: false
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["rules"]),
                  _createVNode(_component_el_form_item, {
                    label: "Segments",
                    prop: "segmentList"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_SegmentsAutocompleteMultiselect, {
                        modelValue: pspLimit.segmentList,
                        "onUpdate:modelValue": ($event: any) => ((pspLimit.segmentList) = $event)
                      }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_el_button, {
                      type: "danger",
                      icon: _ctx.icons.Delete,
                      plain: "",
                      onClick: ($event: any) => (_ctx.deleteLimitItem(index))
                    }, null, 8 /* PROPS */, ["icon", "onClick"])
                  ])
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["model"])
        ]))
      }), 128 /* KEYED_FRAGMENT */)),
      _createVNode(_component_el_button, {
        type: "primary",
        icon: _ctx.icons.Plus,
        plain: "",
        onClick: _ctx.addLimitItem
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add limit ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["icon", "onClick"])
    ]),
    _: 1 /* STABLE */
  }))
}