import { ApiResourceMethod } from '@/api/api-requests-factory';
import { TwoFactorPayload } from '@/models/auth/auth.model';

export default class TwoFactorCodeRequest extends ApiResourceMethod<boolean> {
  public perform({ code }: TwoFactorPayload): Promise<boolean> {
    return this.performer.requestWithMapper(
      { data: { code } },
      (response) => response.code === 0);
  }
}
