import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ps-limits-details__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_LimitsCell = _resolveComponent("LimitsCell")!
  const _component_BooleanCell = _resolveComponent("BooleanCell")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.depositLimits)
      ? _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          key: 0,
          size: "small",
          data: _ctx.depositLimits,
          border: "",
          "header-cell-class-name": "ps-limits-details__header"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "currency",
              label: "Currency",
              width: "80px"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("b", null, _toDisplayString(row.currency), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "limitDaily",
              label: "PSP daily limit"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_LimitsCell, {
                  "popover-title": "PSP daily limit",
                  limits: row.limitDaily,
                  currency: row.currency
                }, null, 8 /* PROPS */, ["limits", "currency"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "limitWeekly",
              label: "PSP weekly limit"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_LimitsCell, {
                  "popover-title": "PSP weekly limit",
                  limits: row.limitWeekly,
                  currency: row.currency
                }, null, 8 /* PROPS */, ["limits", "currency"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "limitMonthly",
              label: "PSP monthly limit"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_LimitsCell, {
                  "popover-title": "PSP monthly limit",
                  limits: row.limitMonthly,
                  currency: row.currency
                }, null, 8 /* PROPS */, ["limits", "currency"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "hidePaymentMethod",
              label: "Hide PM",
              width: "90px",
              align: "center"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_BooleanCell, {
                  "boolean-value": row.hidePaymentMethod
                }, null, 8 /* PROPS */, ["boolean-value"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              prop: "pendingInclude",
              label: "Include pending",
              width: "90px"
            }, {
              default: _withCtx(({ row }) => [
                _createVNode(_component_BooleanCell, {
                  "boolean-value": row.pendingInclude
                }, null, 8 /* PROPS */, ["boolean-value"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"])), [
          [_directive_loading, _ctx.isDepositLimitsLoading]
        ])
      : _createCommentVNode("v-if", true)
  ]))
}