import { useAuth } from '@/store/auth.store';
import { useEnvStore } from '@/store/env.store';
import { usePermissionsStore } from '@/store/permissions.store';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PermissionsPage',
  setup() {
    const { paymentsPermissions } = storeToRefs(usePermissionsStore());
    const { BACKOFFICE_API_URL, WL_SLUG, CURRENT_PARTENT_ROUTE_NAME } = useEnvStore();
    const { token } = storeToRefs(useAuth());

    return {
      paymentsPermissions,
      BACKOFFICE_API_URL,
      WL_SLUG,
      CURRENT_PARTENT_ROUTE_NAME,
      token,
    };
  },
});
