import { Component, createApp, CreateAppFunction, App, h } from 'vue';
import Root from '@/components/root-component/root-component.vue';
import { createPinia } from 'pinia';
import ElementPlus from 'element-plus';
import router from '@/router/router';
import wrapper from 'vue3-webcomponent-wrapper';

export const initApp = (): void => {
  if (window.customElements.get(CUSTOM_ELEMENT_TAG)) {
    return;
  }

  const createAppWrapper: CreateAppFunction<Element> = (component: Component): App =>
    createApp(component)
      .use(ElementPlus)
      .use(createPinia())
      .use(router);

  const webComponent = wrapper(Root, createAppWrapper, h);
  window.customElements.define(CUSTOM_ELEMENT_TAG, webComponent);

  import('@/styles/common.scss');
};
