import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { UpdateGlobalPaymentMethodData } from '@/models/ps-management/ps-management.model';

export default class UpdateGlobalPaymentMethodRequest extends ApiResourceMethod<{}> {
  public perform(billingPaymentMethodId: string, params: UpdateGlobalPaymentMethodData): Promise<{}> {
    return this.performer.requestWithMapper(
      {
        url: `${billingPaymentMethodId}`,
        data: params,
      },
      (payload: BaseResponse<{}>) => {
        return { data: payload.data };
      },
    );
  }
}
