import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isTwoFactorDialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isTwoFactorDialogVisible) = $event)),
    title: "Two factor authentication",
    "close-on-press-escape": "",
    "append-to-body": "",
    width: "25%",
    center: "",
    class: "two-factor-auth-dialog",
    onClose: _ctx.closeTwoFactorDialog
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        disabled: _ctx.isTwoFactorAuthLoading,
        onClick: _ctx.closeTwoFactorDialog
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Cancel ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"]),
      _createVNode(_component_el_button, {
        type: "success",
        disabled: _ctx.isConfirmActionDisabled,
        loading: _ctx.isTwoFactorAuthLoading,
        onClick: _ctx.confirmAction
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Confirm ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        class: "two-factor-auth-dialog__form",
        disabled: _ctx.isTwoFactorAuthLoading,
        onSubmit: _withModifiers(_ctx.confirmAction, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_input, {
            modelValue: _ctx.twoFactorCode,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.twoFactorCode) = $event)),
            placeholder: "Please enter your 2FA code",
            clearable: "",
            autofocus: "",
            "prefix-icon": _ctx.icons.Lock
          }, null, 8 /* PROPS */, ["modelValue", "prefix-icon"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onSubmit"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onClose"]))
}