import { TransactionOperatingSystemListItem } from '@/models/dictionary/transaction-operating-system-list.model';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { defineStore } from 'pinia';

interface TransactionOperatingSystemListState {
  transactionOperatingSystemList: TransactionOperatingSystemListItem[];
  isTransactionOperatingSystemListLoading: boolean;
}

export const useTransactionOperatingSystemListStore = defineStore({
  id: 'TransactionOperatingSystemListStore',
  state: (): TransactionOperatingSystemListState => ({
    transactionOperatingSystemList: [],
    isTransactionOperatingSystemListLoading: false,
  }),
  actions: {
    setTransactionOperatingSystemListLoading(isLoading: boolean): void {
      this.isTransactionOperatingSystemListLoading = isLoading;
    },
    getTransactionOperatingSystemList(): Promise<TransactionOperatingSystemListItem[] | void> {
      if (this.transactionOperatingSystemList.length > 0) {
        return Promise.resolve(this.transactionOperatingSystemList);
      }

      this.setTransactionOperatingSystemListLoading(true);

      return api.dictionary.getTransactionOperatingSystemListRequest.perform()
        .then((data) => {
          this.transactionOperatingSystemList = data;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return Promise.reject(error);
        })
        .finally(() => {
          this.setTransactionOperatingSystemListLoading(false);
        });
    },
  },
});
