import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { AdminEmailListRequestPayload, AdminEmailListItem } from '@/models/dictionary/admins.model';

export default class GetAdminEmailListRequest extends ApiResourceMethod<AdminEmailListItem[]> {
  public perform(requestPayload: AdminEmailListRequestPayload): Promise<AdminEmailListItem[]> {
    return this.performer.requestWithMapper(
      { params: requestPayload },
      (payload: BaseResponse<AdminEmailListItem[]>) => {
        return payload.data;
      },
    );
  }
}
