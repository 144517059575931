import { api } from '@/services/http.service';
import { defineStore } from 'pinia';
import { SegmentItem, SegmentListFilter } from '@/models/dictionary/segments.model';
import { ElNotification } from 'element-plus';

interface SegmentsStore {
  segmentList: SegmentItem[];
  isSegmentListLoading: boolean;
  autocompleteSearchResult: Array<{ label: string; value: SegmentItem }>;
  isAutocompleteSearchLoading: boolean;
}

export const useSegmentsStore = defineStore({
  id: 'SegmentsStore',
  state: (): SegmentsStore => ({
    segmentList: [],
    isSegmentListLoading: false,
    autocompleteSearchResult: [],
    isAutocompleteSearchLoading: false,
  }),
  actions: {
    setSegmentListLoading(isLoading: boolean): void {
      this.isSegmentListLoading = isLoading;
    },
    getSegmentList(filterList: SegmentListFilter): Promise<SegmentItem[] | void> {
      if (this.segmentList.length) {
        return Promise.resolve(this.segmentList);
      }

      this.setSegmentListLoading(true);

      const requestPayload = {
        filterList,
      };

      return api.dictionary.getSegmentListRequest.perform(requestPayload)
        .then(response => {
          this.segmentList = response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        }).finally(() => this.setSegmentListLoading(false));
    },
    getAutocompleteSegmentList(filterList: SegmentListFilter): Promise<SegmentItem[]> {
      this.setAutocompleteSearchLoading(true);

      const requestPayload = {
        filterList,
      };

      return api.dictionary.getSegmentListRequest.perform(requestPayload)
        .then(response => {
          if (!response.length) {
            return [];
          }

          this.autocompleteSearchResult = response.map(segment => ({
            label: segment.name,
            value: segment,
          }));

          return response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return [];
        }).finally(() => this.setAutocompleteSearchLoading(false));
    },
    clearAutocompleteSearchResult(): void {
      this.autocompleteSearchResult = [];
    },
    setAutocompleteSearchResult(segmentList: SegmentItem[]): void {
      this.autocompleteSearchResult = segmentList.map(segment => ({
        label: segment.name,
        value: segment,
      }));
    },
    setAutocompleteSearchLoading(isLoading: boolean): void {
      this.isAutocompleteSearchLoading = isLoading;
    },
    autocompleteSearch(name: string) {
      if (!name) {
        return;
      }

      const filteredSegmentsList = this.segmentList.filter(segment => segment.name.toLowerCase().includes(name));

      return filteredSegmentsList;
    },
  },
});
