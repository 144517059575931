import { defineComponent } from 'vue';
import { Plus, Delete } from '@element-plus/icons-vue';
import {
  useGlobalPsManagementStore,
  globalPaymentMethodsCurrencyFormRef,
} from '@/store/ps-management/global-payment-methods.store';
import { storeToRefs } from 'pinia';
import { DEFAULT_CURRENCY_ITEM } from '@/models/ps-management/ps-management.model';
import { globalPaymentMethodsFormCurrencyRules } from '@/hooks/ps-management/global-payment-methods-validation-rules';

const icons = {
  Plus,
  Delete,
};

export default defineComponent({
  name: 'LimitCurrencyConfiguration',
  setup() {
    const globalPsManagementStore = useGlobalPsManagementStore();
    const {
      globalPaymentMethodsSettingsFormData,
      availableCurrencyList,
    } = storeToRefs(globalPsManagementStore);

    const addCurrencyItem = (): void => {
      globalPaymentMethodsSettingsFormData.value.depositLimitList.push({ ...DEFAULT_CURRENCY_ITEM });
      globalPaymentMethodsSettingsFormData.value.withdrawLimitList.push({ ...DEFAULT_CURRENCY_ITEM });
    };

    const deleteCurrencyItem = (index: number): void => {
      globalPaymentMethodsSettingsFormData.value.depositLimitList.splice(index, 1);
      globalPaymentMethodsSettingsFormData.value.withdrawLimitList.splice(index, 1);
    };

    const updateCurrencyByIndex = (selectedCurrency: string, currencyIndex: number): void => {
      globalPaymentMethodsSettingsFormData.value.withdrawLimitList[currencyIndex].currency = selectedCurrency;
    };

    return {
      icons,
      addCurrencyItem,
      globalPaymentMethodsSettingsFormData,
      deleteCurrencyItem,
      availableCurrencyList,
      updateCurrencyByIndex,
      globalPaymentMethodsFormCurrencyRules,
      globalPaymentMethodsCurrencyFormRef,
    };
  },
});
