import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'BooleanCell',
  props: {
    booleanValue: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const booleanTitle = computed(() => props.booleanValue ? 'Y' : 'N');

    return {
      booleanTitle,
    };
  },
});
