import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { GlobalPaymentMethodItem } from '@/models/ps-management/ps-management.model';

export default class GetGlobalPaymentMethodRequest extends ApiResourceMethod<GlobalPaymentMethodItem> {
  public perform(billingPaymentMethodId: string): Promise<GlobalPaymentMethodItem> {
    return this.performer.requestWithMapper(
      { url: `${billingPaymentMethodId}` },
      (payload: BaseResponse<GlobalPaymentMethodItem>) => {
        return payload.data;
      },
    );
  }
}
