import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { PaymentAccountListItem } from '@/models/dictionary/payment-account-list.model';

export default class GetPaymentAccountListRequest extends ApiResourceMethod<PaymentAccountListItem[]> {
  public perform(): Promise<PaymentAccountListItem[]> {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<PaymentAccountListItem[]>) => {
        return payload.data;
      },
    );
  }
}
