import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import { Ref } from 'vue';
import GetFileUrlByIdRequest from './file-url.request';
import UploadImageRequest from './upload-image.request';

export class FileApiResources {
  public constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly apiUrl: Ref<string>,
  ) {}

  public get getFileUrlByIdRequest(): GetFileUrlByIdRequest {
    return new GetFileUrlByIdRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/file`,
      HttpMethods.GET,
    );
  }

  public get uploadImageRequest(): UploadImageRequest {
    return new UploadImageRequest(
      this.requestPerformer,
      this.apiUrl.value,
      HttpMethods.POST,
    );
  }
}
