import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { GeoPaymentMethodItem } from '@/models/ps-management/ps-management.model';

export default class GetGeoPaymentMethodRequest extends ApiResourceMethod<GeoPaymentMethodItem> {
  public perform(billingPaymentMethodSettingsId: string): Promise<GeoPaymentMethodItem> {
    return this.performer.requestWithMapper(
      { url: `${billingPaymentMethodSettingsId}` },
      (payload: BaseResponse<GeoPaymentMethodItem>) => {
        return payload.data;
      },
    );
  }
}
