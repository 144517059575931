import { defineStore } from 'pinia';

type AuthState = {
  token: string;
};

export const useAuth = defineStore({
  id: 'auth',
  state: (): AuthState => ({
    token: localStorage.getItem('token') || '',
  }),
  actions: {
    setToken(token: string) {
      localStorage.setItem('token', token);
      this.token = token;
    },
  },
});
