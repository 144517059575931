import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { SegmentItem, SegmentListRequestModel } from '@/models/dictionary/segments.model';

export default class GetSegmentListRequest extends ApiResourceMethod<SegmentItem[]> {
  public perform({ filterList }: SegmentListRequestModel): Promise<SegmentItem[]> {
    const requestData = {
      filterList,
    };

    return this.performer.requestWithMapper(
      { params: requestData },
      (payload: BaseResponse<SegmentItem[]>) => {
        return payload.data;
      },
    );
  }
}
