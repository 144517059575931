import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';

export default class GetFileUrlByIdRequest extends ApiResourceMethod<string> {
  public perform(fileId: string): Promise<string> {
    return this.performer.requestWithMapper(
      {
        url: `${fileId}`,
      },
      (payload: BaseResponse<{ filePath: string }>) => {
        return payload.data.filePath;
      },
    );
  }
}
