import { defineStore } from 'pinia';
import { api } from '@/services/http.service';
import { AdminEmailListRequestPayload, AdminEmailListItem } from '@/models/dictionary/admins.model';
import { ElNotification } from 'element-plus';

interface AdminsState {
  adminEmailList: AdminEmailListItem[];
  isAdminEmailListLoading: boolean;
}

export const useAdminsStore = defineStore({
  id: 'AdminsStore',
  state: (): AdminsState => ({
    adminEmailList: [],
    isAdminEmailListLoading: false,
  }),
  actions: {
    clearAdminEmailList(): void {
      this.adminEmailList = [];
    },
    setAdminEmailListLoading(isLoading: boolean): void {
      this.isAdminEmailListLoading = isLoading;
    },
    getAdminEmailList(filterList: AdminEmailListRequestPayload): Promise<void | AdminEmailListItem[]> | undefined {
      if (!filterList.filterList.adminEmail?.length) {
        this.clearAdminEmailList();

        return;
      }
      this.setAdminEmailListLoading(true);

      return api.dictionary.getAdminEmailListRequest.perform(filterList)
        .then((data) => {
          this.adminEmailList = data;

          return data;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        }).finally(() => {
          this.setAdminEmailListLoading(false);
        });
    },
  },
});
