import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "add-geo-payment-methods-dialog__option_logo-container" }
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isAddGeoPaymentMethodsDialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isAddGeoPaymentMethodsDialogVisible) = $event)),
    title: "Add geo payment methods",
    "close-on-press-escape": "",
    width: "40%",
    center: "",
    onClose: _ctx.closeGeoPaymentMethodsAddingDialog
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_el_button, {
        disabled: _ctx.isGlobalPaymentMethodListLoading,
        onClick: _ctx.closeGeoPaymentMethodsAddingDialog
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Cancel ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onClick"]),
      _createVNode(_component_el_button, {
        type: "primary",
        disabled: _ctx.isGlobalPaymentMethodListLoading || !_ctx.selectedPaymentMethods.length,
        loading: _ctx.isGlobalPaymentMethodListLoading,
        onClick: _ctx.addSelectedPaymentMethodsToGeo
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "loading", "onClick"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        disabled: _ctx.isGlobalPaymentMethodListLoading,
        onSubmit: _withModifiers(_ctx.addSelectedPaymentMethodsToGeo, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.selectedPaymentMethods,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPaymentMethods) = $event)),
            loading: _ctx.isGlobalPaymentMethodListLoading,
            placeholder: "Select payment methods",
            multiple: "",
            filterable: "",
            "value-key": "billingPaymentMethodId",
            onOpen: _ctx.getGlobalPaymentMethodList
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAvailableGlobalPaymentMethods(), (globalPaymentMethod) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: globalPaymentMethod.billingPaymentMethodId,
                  label: globalPaymentMethod.paymentSystem,
                  value: globalPaymentMethod,
                  class: "add-geo-payment-methods-dialog__option"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("img", {
                        src: globalPaymentMethod.filePath,
                        class: "add-geo-payment-methods-dialog__option_logo-image",
                        alt: globalPaymentMethod.paymentSystem
                      }, null, 8 /* PROPS */, _hoisted_2)
                    ]),
                    _createTextVNode(" " + _toDisplayString(globalPaymentMethod.paymentSystem), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue", "loading", "onOpen"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["disabled", "onSubmit"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onClose"]))
}