import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "boolean-cell" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["boolean-cell__value", `boolean-cell__value_${_ctx.booleanValue ? 'green' : 'red'}`])
    }, _toDisplayString(_ctx.booleanTitle), 3 /* TEXT, CLASS */)
  ]))
}