import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { BillingProdiverListItem } from '@/models/dictionary/billing-provider-list.model';

export default class GetBillingProviderListRequest extends ApiResourceMethod<BillingProdiverListItem[]> {
  public perform(): Promise<BillingProdiverListItem[]> {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<BillingProdiverListItem[]>) => {
        return payload.data;
      },
    );
  }
}
