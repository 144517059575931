import { defineComponent } from 'vue';
import { InfoFilled } from '@element-plus/icons-vue';
import { HINT_LIST } from '@/models/hint.model';

export default defineComponent({
  name: 'HintComponent',
  components: { InfoFilled },
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const hintInfo = HINT_LIST[props.name];

    return {
      hintInfo,
    };
  },
});
