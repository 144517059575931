import { useTwoFactorStore } from '@/store/two-factor-dialog.store';
import { storeToRefs } from 'pinia';
import { defineComponent, computed } from 'vue';
import { Lock } from '@element-plus/icons-vue';
import { ElNotification } from 'element-plus';

const icons = {
  Lock,
};

export default defineComponent({
  name: 'TwoFactorAuthDialog',
  props: {
    action: {
      type: Function,
      required: true,
    },
  },
  emits: ['confirm'],
  setup(props, { emit }) {
    const twoFactorStore = useTwoFactorStore();
    const { checkTwoFactorCode, closeTwoFactorDialog } = twoFactorStore;
    const {
      twoFactorCode,
      isTwoFactorDialogVisible,
      isTwoFactorAuthLoading,
      isEnteredCodeFormallyValid,
    } = storeToRefs(twoFactorStore);

    const isConfirmActionDisabled = computed((): boolean => {
      return isTwoFactorAuthLoading.value || !isEnteredCodeFormallyValid.value;
    });

    const confirmAction = (): void => {
      if (!isEnteredCodeFormallyValid.value) {
        ElNotification({
          title: 'Wrong code',
          message: 'Code must be 6 digits long',
          type: 'error',
        });

        return;
      }

      checkTwoFactorCode(twoFactorCode.value as string)
        .then(() => {
          if (!props.action) {
            emit('confirm', twoFactorCode.value);

            return;
          }

          props.action(twoFactorCode.value);
          closeTwoFactorDialog();
        });
    };

    return {
      icons,
      checkTwoFactorCode,
      twoFactorCode,
      confirmAction,
      closeTwoFactorDialog,
      isTwoFactorDialogVisible,
      isTwoFactorAuthLoading,
      isConfirmActionDisabled,
    };
  },
});
