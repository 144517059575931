import { defineStore } from 'pinia';
import { api } from '@/services/http.service';
import {
  BillingPaymentMethodDepositLimit,
  GeoPaymentMethodDepositLimits,
} from '@/models/ps-management/limits.model';
import { ElNotification, FormInstance } from 'element-plus';
import { ref } from 'vue';
import { useCurrencyListStore } from '../dictionary/currency-list.store';

interface PaymentMethodsDepositLimitsStore {
  currentGeoPaymentMethodId: string;
  depositLimitsData: Record<string, {
    depositLimitsList: GeoPaymentMethodDepositLimits[];
  }> | {};
  isDepositLimitsLoading: boolean;
  isUpdatingDepositLimits: boolean;
  isPspLimitsSettingsSidebarVisible: boolean;
}

export const pspLimitsSettingsFormRef = ref<FormInstance>();

export const usePaymentMethodsDepositLimitsStore = defineStore({
  id: 'PaymentMethodsDepositLimitsStore',
  state: (): PaymentMethodsDepositLimitsStore => ({
    currentGeoPaymentMethodId: '',
    depositLimitsData: {},
    isDepositLimitsLoading: false,
    isUpdatingDepositLimits: false,
    isPspLimitsSettingsSidebarVisible: false,
  }),
  getters: {
    availableCurrencies(state) {
      if (!state.currentGeoPaymentMethodId) {
        return;
      }

      const selectedCurrencies = state.depositLimitsData[state.currentGeoPaymentMethodId].depositLimitsList.map(
        limit => limit.currency,
      );

      const { currencyList } = useCurrencyListStore();

      return currencyList.filter(currency => !selectedCurrencies.includes(currency.code));
    },
  },
  actions: {
    setDepositLimitsLoading(isLoading) {
      this.isDepositLimitsLoading = isLoading;
    },
    setUpdateDepositLimitsLoading(isLoading) {
      this.isUpdatingDepositLimits = isLoading;
    },
    getGeoPaymentMethodDepositLimits(geoPaymentMethodId: string): Promise<GeoPaymentMethodDepositLimits[] | void> {
      if (!geoPaymentMethodId) {
        return Promise.resolve([]);
      }

      this.setDepositLimitsLoading(true);

      return api.psManagement.getGeoPaymentMethodDepositLimitsRequest
        .perform(geoPaymentMethodId)
        .then((response: GeoPaymentMethodDepositLimits[]) => {
          this.depositLimitsData[geoPaymentMethodId] = { depositLimitsList: response };

          return response;
        })
        .catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        })
        .finally(() => {
          this.setDepositLimitsLoading(false);
        });
    },
    updateGeoPaymentMethodDepositLimits(depositLimitList: BillingPaymentMethodDepositLimit) {
      this.setUpdateDepositLimitsLoading(true);

      return api.psManagement.updateGeoPaymentMethodDepositLimitsRequest
        .perform(depositLimitList)
        .then(() => {
          ElNotification({
            title: 'Success',
            message: 'Deposit limit updated',
            type: 'success',
          });
          this.closePspLimitsSettings();
        })
        .finally(() => {
          this.setUpdateDepositLimitsLoading(false);
        });
    },
    setPspLimitsSettingsSidebarVisible(isVisible: boolean): void {
      this.isPspLimitsSettingsSidebarVisible = isVisible;
    },
    openPspLimitsSettings(geoPaymentMethodId: string) {
      this.setPspLimitsSettingsSidebarVisible(true);
      this.currentGeoPaymentMethodId = geoPaymentMethodId;
    },
    closePspLimitsSettings() {
      this.setPspLimitsSettingsSidebarVisible(false);
      this.currentGeoPaymentMethodId = '';
    },
  },
});
