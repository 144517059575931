import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import { Ref } from 'vue';
import GetCountryListRequest from '@/api/dictionary/country-list.request';
import GetSegmentListRequest from '@/api/dictionary/segmet-list.request';
import GetCurrencyListRequest from '@/api/dictionary/currency-list-request';
import GetBillingProviderListRequest from '@/api/dictionary/billing-provider-list.request';
import GetPaymentAccountListRequest from '@/api/dictionary/payment-account-list.request';
import GetPaymentListRequest from '@/api/dictionary/payment-list.request';
import GetPaymentOperationListRequest from './payment-operation-list.request';
import GetPaymentSystemListRequest from '@/api/dictionary/payment-system-list.request';
import GetTransactionOperationSystemListRequest from '@/api/dictionary/transaction-operating-system-list.request';
import GetAdminEmailListRequest from '@/api/dictionary/admin-email-list.request';
import GetPaymentTypeListRequest from '@/api/dictionary/get-payment-type-list.request';

export class DictionaryApiResources {
  public constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly apiUrl: Ref<string>,
  ) {}

  public get getCountryListRequest(): GetCountryListRequest {
    return new GetCountryListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/country`,
      HttpMethods.GET,
    );
  }

  public get getSegmentListRequest(): GetSegmentListRequest {
    return new GetSegmentListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/segment`,
      HttpMethods.GET,
    );
  }

  public get getCurrencyListRequest(): GetCurrencyListRequest {
    return new GetCurrencyListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/currency`,
      HttpMethods.GET,
    );
  }

  public get getBillingProviderListRequest(): GetBillingProviderListRequest {
    return new GetBillingProviderListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/bpId`,
      HttpMethods.GET,
    );
  }

  public get getPaymentAccountListRequest(): GetPaymentAccountListRequest {
    return new GetPaymentAccountListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/paymentAccount`,
      HttpMethods.GET,
    );
  }

  public get getPaymentListRequest(): GetPaymentListRequest {
    return new GetPaymentListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/payment`,
      HttpMethods.GET,
    );
  }

  public get getPaymentOperationListRequest(): GetPaymentOperationListRequest {
    return new GetPaymentOperationListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/paymentOperation`,
      HttpMethods.GET,
    );
  }

  public get getPaymentSystemListRequest(): GetPaymentSystemListRequest {
    return new GetPaymentSystemListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/paymentSystem`,
      HttpMethods.GET,
    );
  }

  public get getTransactionOperatingSystemListRequest(): GetTransactionOperationSystemListRequest {
    return new GetTransactionOperationSystemListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/bpTransactionOs`,
      HttpMethods.GET,
    );
  }

  public get getAdminEmailListRequest(): GetAdminEmailListRequest {
    return new GetAdminEmailListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/adminEmailList`,
      HttpMethods.GET,
    );
  }

  public get getPaymentTypeListRequest(): GetPaymentTypeListRequest {
    return new GetPaymentTypeListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/dictionary/paymentTypeList`,
      HttpMethods.GET,
    );
  }
}
