import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import type { PaymentTypeItem } from '@/models/dictionary/payment-type-list.model';

export default class GetPaymentTypeListRequest extends ApiResourceMethod<PaymentTypeItem[]> {
  public perform(): Promise<PaymentTypeItem[]> {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<PaymentTypeItem[]>) => {
        return payload.data;
      },
    );
  }
}
