import { ResponseHeaders } from '@/models/app-internal/headers.model';
import { Ref, ref } from 'vue';

export enum TokenPaginationEvents {
  UPDATE_LIMIT = 'updateLimit',
  CHANGE_PAGE = 'changePage',
}

interface UseTokenPaginationHook {
  nextPageToken: Ref<string>;
  prevPageToken: Ref<string>;
  processResponseHeaders: (headers: Record<string, string>) => void;
  setPrevPageToken: (token: string) => void;
  setNextPageToken: (token: string) => void;
  updatePagination: (
    { prevPageToken, nextPageToken }: { prevPageToken?: string; nextPageToken?: string }
  ) => void;
  resetPaginationTokens: () => void;
}

const nextPageToken = ref('');
const prevPageToken = ref('');

const setPrevPageToken = (token: string): void => {
  prevPageToken.value = token;
};

const setNextPageToken = (token: string): void => {
  nextPageToken.value = token;
};

const setDefaultTokens = (): void => {
  setNextPageToken('');
  setPrevPageToken('');
};

const resetPaginationTokens = (): void => {
  setDefaultTokens();
};

const processResponseHeaders = (headers: Record<string, string>): void => {
  if (headers[ResponseHeaders.NEXT_PAGE_TOKEN_HEADER] || headers[ResponseHeaders.PREV_PAGE_TOKEN_HEADER]) {
    setNextPageToken(headers[ResponseHeaders.NEXT_PAGE_TOKEN_HEADER] ?? '');
    setPrevPageToken(headers[ResponseHeaders.PREV_PAGE_TOKEN_HEADER] ?? '');
  }

  if (!headers[ResponseHeaders.NEXT_PAGE_TOKEN_HEADER] && !headers[ResponseHeaders.PREV_PAGE_TOKEN_HEADER]) {
    resetPaginationTokens();
  }
};

const updatePagination = (
  { prevPageToken, nextPageToken }: { prevPageToken?: string; nextPageToken?: string },
): void => {
  setPrevPageToken(prevPageToken ?? '');
  setNextPageToken(nextPageToken ?? '');
};

export default function useTokenPagination(): UseTokenPaginationHook {
  return {
    nextPageToken,
    prevPageToken,
    processResponseHeaders,
    setPrevPageToken,
    setNextPageToken,
    updatePagination,
    resetPaginationTokens,
  };
}
