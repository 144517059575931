import { TransactionListFilterModel } from '@/models/transaction/transaction-list-filter.model';
import { ColumnFilterAlignments, ColumnFilterItem } from '../column-filter/column-filter.model';
import { TagComponentTypes } from '../app-common.model';

export type TransactionListRequestModel = {
  limit?: number;
  sort?: string;
  filterList?: TransactionListFilterModel;
  prevPageToken?: string;
  nextPageToken?: string;
}

export type TransactionListItem = {
  bpWlTransactionId: string;
  playerEmail: string;
  playerId: string;
  createdAt: number;
  updatedAt: number;
  operation: string;
  amount: string;
  currency: string;
  paymentSystem: string;
  paymentStatusInfo: string[];
  autoApproveRejectByRuleList: string[];
  finishedAt: number;
  transactionId: string;
  psp: string;
  pspAccount: string;
  country: string;
  bpId: string;
  accountHolderName: string;
  ipAddress: string;
  amountEur: string;
  firstDeposit: boolean;
  cryptoAddress: string;
  cryptoAddressTag: string;
  os: string;
  paymentAccount: string;
  backOfficeAdminEmail: string;
  moderationStatus: string;
  transactionStatus: string;
  processingType: string;
  currentStatusReason: string;
  executeAfter: number;
  affiliateId: string;
  maskedFieldList: string[];
}

export type TransactionListResponseModel = {
  data: TransactionListItem[];
  headers: Record<string, string>;
};

export enum TransactionListColumnProperties {
  TRANSACTION_ID = 'transactionId',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  PLAYER_EMAIL = 'playerEmail',
  AFFILIATE_ID = 'affiliateId',
  COUNTRY = 'country',
  IP_ADDRESS = 'ipAddress',
  OPERATION = 'operation',
  TRANSACTION_STATUS = 'transactionStatus',
  PAYMENT_STATUS_INFO = 'paymentStatusInfo',
  PROCESSING_TYPE = 'processingType',
  AUTO_APPROVE_REJECT_BY_RULE_LIST = 'autoApproveRejectByRuleList',
  MODERATION_STATUS = 'moderationStatus',
  CURRENCY = 'currency',
  AMOUNT = 'amount',
  AMOUNT_EUR = 'amountEur',
  FIRST_DEPOSIT = 'firstDeposit',
  PAYMENT_ACCOUNT = 'paymentAccount',
  PSP = 'psp',
  PSP_ACCOUNT = 'pspAccount',
  BP_ID = 'bpId',
  PAYMENT_SYSTEM = 'paymentSystem',
  ACCOUNT_HOLDER_NAME = 'accountHolderName',
  OS = 'os',
  BACK_OFFICE_ADMIN_EMAIL = 'backOfficeAdminEmail',
}

export enum TransactionListColumnLabels {
  TRANSACTION_ID = 'Transaction ID',
  CREATED_AT = 'Created',
  UPDATED_AT = 'Updated',
  PLAYER_EMAIL = 'Player',
  AFFILIATE_ID = 'Affiliate ID',
  COUNTRY = 'Country',
  IP_ADDRESS = 'IP country',
  OPERATION = 'Operation',
  TRANSACTION_STATUS = 'Status',
  PAYMENT_STATUS_INFO = 'Payment status info',
  PROCESSING_TYPE = 'Processing type',
  AUTO_APPROVE_REJECT_BY_RULE_LIST = 'AutoWD reject by rule',
  MODERATION_STATUS = 'Moderation status',
  CURRENCY = 'Currency',
  AMOUNT = 'Amount',
  AMOUNT_EUR = 'In EUR',
  FIRST_DEPOSIT = 'First attempt',
  PAYMENT_ACCOUNT = 'Payment account',
  PSP = 'PSP',
  PSP_ACCOUNT = 'PSP account',
  BP_ID = 'Billing provider',
  PAYMENT_SYSTEM = 'Payment system',
  ACCOUNT_HOLDER_NAME = 'Account holder',
  OS = 'OS',
  BACK_OFFICE_ADMIN_EMAIL = 'Admin',
}

export const transactionListColumnLabels = {
  [TransactionListColumnProperties.TRANSACTION_ID]: TransactionListColumnLabels.TRANSACTION_ID,
  [TransactionListColumnProperties.CREATED_AT]: TransactionListColumnLabels.CREATED_AT,
  [TransactionListColumnProperties.UPDATED_AT]: TransactionListColumnLabels.UPDATED_AT,
  [TransactionListColumnProperties.PLAYER_EMAIL]: TransactionListColumnLabels.PLAYER_EMAIL,
  [TransactionListColumnProperties.AFFILIATE_ID]: TransactionListColumnLabels.AFFILIATE_ID,
  [TransactionListColumnProperties.COUNTRY]: TransactionListColumnLabels.COUNTRY,
  [TransactionListColumnProperties.IP_ADDRESS]: TransactionListColumnLabels.IP_ADDRESS,
  [TransactionListColumnProperties.OPERATION]: TransactionListColumnLabels.OPERATION,
  [TransactionListColumnProperties.TRANSACTION_STATUS]: TransactionListColumnLabels.TRANSACTION_STATUS,
  [TransactionListColumnProperties.PAYMENT_STATUS_INFO]: TransactionListColumnLabels.PAYMENT_STATUS_INFO,
  [TransactionListColumnProperties.PROCESSING_TYPE]: TransactionListColumnLabels.PROCESSING_TYPE,
  [TransactionListColumnProperties.AUTO_APPROVE_REJECT_BY_RULE_LIST]:
    TransactionListColumnLabels.AUTO_APPROVE_REJECT_BY_RULE_LIST,
  [TransactionListColumnProperties.MODERATION_STATUS]: TransactionListColumnLabels.MODERATION_STATUS,
  [TransactionListColumnProperties.CURRENCY]: TransactionListColumnLabels.CURRENCY,
  [TransactionListColumnProperties.AMOUNT]: TransactionListColumnLabels.AMOUNT,
  [TransactionListColumnProperties.AMOUNT_EUR]: TransactionListColumnLabels.AMOUNT_EUR,
  [TransactionListColumnProperties.FIRST_DEPOSIT]: TransactionListColumnLabels.FIRST_DEPOSIT,
  [TransactionListColumnProperties.PAYMENT_ACCOUNT]: TransactionListColumnLabels.PAYMENT_ACCOUNT,
  [TransactionListColumnProperties.PSP]: TransactionListColumnLabels.PSP,
  [TransactionListColumnProperties.PSP_ACCOUNT]: TransactionListColumnLabels.PSP_ACCOUNT,
  [TransactionListColumnProperties.BP_ID]: TransactionListColumnLabels.BP_ID,
  [TransactionListColumnProperties.PAYMENT_SYSTEM]: TransactionListColumnLabels.PAYMENT_SYSTEM,
  [TransactionListColumnProperties.ACCOUNT_HOLDER_NAME]: TransactionListColumnLabels.ACCOUNT_HOLDER_NAME,
  [TransactionListColumnProperties.OS]: TransactionListColumnLabels.OS,
  [TransactionListColumnProperties.BACK_OFFICE_ADMIN_EMAIL]: TransactionListColumnLabels.BACK_OFFICE_ADMIN_EMAIL,
};

export const transactionListColumns: ColumnFilterItem[] = [
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.TRANSACTION_ID],
    value: TransactionListColumnProperties.TRANSACTION_ID,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.CREATED_AT],
    value: TransactionListColumnProperties.CREATED_AT,
    isVisible: true,
    width: 135,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.UPDATED_AT],
    value: TransactionListColumnProperties.UPDATED_AT,
    isVisible: true,
    isSortable: true,
    width: 135,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.PLAYER_EMAIL],
    value: TransactionListColumnProperties.PLAYER_EMAIL,
    isVisible: true,
    width: 200,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.AFFILIATE_ID],
    value: TransactionListColumnProperties.AFFILIATE_ID,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.COUNTRY],
    value: TransactionListColumnProperties.COUNTRY,
    isVisible: true,
    width: 80,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.IP_ADDRESS],
    value: TransactionListColumnProperties.IP_ADDRESS,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.OPERATION],
    value: TransactionListColumnProperties.OPERATION,
    isVisible: true,
    width: 100,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.TRANSACTION_STATUS],
    value: TransactionListColumnProperties.TRANSACTION_STATUS,
    isVisible: true,
    width: 140,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.PAYMENT_STATUS_INFO],
    value: TransactionListColumnProperties.PAYMENT_STATUS_INFO,
    isVisible: true,
    width: 230,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.PROCESSING_TYPE],
    value: TransactionListColumnProperties.PROCESSING_TYPE,
    isVisible: true,
    width: 120,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.AUTO_APPROVE_REJECT_BY_RULE_LIST],
    value: TransactionListColumnProperties.AUTO_APPROVE_REJECT_BY_RULE_LIST,
    isVisible: true,
    width: 220,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.MODERATION_STATUS],
    value: TransactionListColumnProperties.MODERATION_STATUS,
    isVisible: true,
    width: 130,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.CURRENCY],
    value: TransactionListColumnProperties.CURRENCY,
    isVisible: true,
    width: 90,
    align: ColumnFilterAlignments.CENTER,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.AMOUNT],
    value: TransactionListColumnProperties.AMOUNT,
    isVisible: true,
    width: 80,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.AMOUNT_EUR],
    value: TransactionListColumnProperties.AMOUNT_EUR,
    isVisible: true,
    width: 80,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.FIRST_DEPOSIT],
    value: TransactionListColumnProperties.FIRST_DEPOSIT,
    isVisible: true,
    width: 110,
    align: ColumnFilterAlignments.CENTER,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.PAYMENT_ACCOUNT],
    value: TransactionListColumnProperties.PAYMENT_ACCOUNT,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.PSP],
    value: TransactionListColumnProperties.PSP,
    isVisible: true,
    width: 100,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.PSP_ACCOUNT],
    value: TransactionListColumnProperties.PSP_ACCOUNT,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.BP_ID],
    value: TransactionListColumnProperties.BP_ID,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.PAYMENT_SYSTEM],
    value: TransactionListColumnProperties.PAYMENT_SYSTEM,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.ACCOUNT_HOLDER_NAME],
    value: TransactionListColumnProperties.ACCOUNT_HOLDER_NAME,
    isVisible: true,
    width: 150,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.OS],
    value: TransactionListColumnProperties.OS,
    isVisible: true,
    width: 90,
  },
  {
    label: transactionListColumnLabels[TransactionListColumnProperties.BACK_OFFICE_ADMIN_EMAIL],
    value: TransactionListColumnProperties.BACK_OFFICE_ADMIN_EMAIL,
    isVisible: true,
    width: 150,
  },
];

export const transactionListColumnsStorageKey = 'TransactionList_columns';

export enum PaymentStatuses {
  PAID = 'paid',
  PENDING = 'pending',
  CANCELLED = 'cancelled',
  PROCESSED = 'processed',
  STUCK = 'stuck',
  HOLD = 'hold',
  INITIATED = 'initiated',
  UNCOMPLETED = 'uncompleted',
  KYC_VERIFICATION = 'kycVerification',
  MANUAL_CANCEL = 'manualCancel',
  MANUAL_PAID = 'manualPaid',
}

export const paymentStatusTagTypes = {
  [PaymentStatuses.PAID]: TagComponentTypes.SUCCESS,
  [PaymentStatuses.PENDING]: TagComponentTypes.WARNING,
  [PaymentStatuses.CANCELLED]: TagComponentTypes.INFO,
  [PaymentStatuses.PROCESSED]: TagComponentTypes.SUCCESS,
  [PaymentStatuses.STUCK]: TagComponentTypes.WARNING,
  [PaymentStatuses.HOLD]: TagComponentTypes.WARNING,
  [PaymentStatuses.INITIATED]: TagComponentTypes.SUCCESS,
  [PaymentStatuses.UNCOMPLETED]: TagComponentTypes.INFO,
  [PaymentStatuses.KYC_VERIFICATION]: TagComponentTypes.SUCCESS,
  [PaymentStatuses.MANUAL_CANCEL]: TagComponentTypes.INFO,
  [PaymentStatuses.MANUAL_PAID]: TagComponentTypes.SUCCESS,
};

export enum OperationStatuses {
  WITHDRAW = 'withdraw',
  CHARGEBACK = 'chargeback',
}

export const operationStatusTagTypes = {
  [OperationStatuses.WITHDRAW]: TagComponentTypes.PRIMARY,
  [OperationStatuses.CHARGEBACK]: TagComponentTypes.DANGER,
};
