/* eslint-disable max-len */
export enum HintNames {
  PSP = 'PSP',
}

export const HINT_LIST = {
  [HintNames.PSP]: {
    title: 'PSP',
    description: 'Before select PSP choose Deposit or Cash out method',
  },
};
