import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "psp-limits-settings__wrapper-currency" }
const _hoisted_2 = { class: "psp-limits-settings__wrapper-limits" }
const _hoisted_3 = { class: "psp-limits-settings__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_popconfirm = _resolveComponent("el-popconfirm")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_PspLimit = _resolveComponent("PspLimit")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_drawer = _resolveComponent("el-drawer")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    modelValue: _ctx.isPspLimitsSettingsSidebarVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isPspLimitsSettingsSidebarVisible) = $event)),
    title: "Edit psp limits",
    "append-to-body": "",
    size: "80%",
    class: "psp-limits-settings",
    onClose: _ctx.closeLimitSettings
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_button, {
          type: "success",
          loading: _ctx.isPspLimitsSettingsLoading || _ctx.isUpdatingDepositLimits,
          onClick: _ctx.savePspLimitsSettings
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Save ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createElementBlock("div", null, [
        (!!_ctx.currentGeoPaymentMethodId && !!_ctx.depositLimitsData[_ctx.currentGeoPaymentMethodId] &&
          _ctx.depositLimitsData[_ctx.currentGeoPaymentMethodId].depositLimitsList.length)
          ? (_openBlock(), _createBlock(_component_el_form, {
              key: 0,
              ref: "pspLimitsSettingsFormRef",
              model: _ctx.depositLimitsData[_ctx.currentGeoPaymentMethodId],
              "label-position": "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_collapse, {
                  modelValue: _ctx.collapseItemNames,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapseItemNames) = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.limitsListData.depositLimitsList, (depositLimit, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "psp-limits-settings__limit-list-item"
                      }, [
                        _createVNode(_component_el_popconfirm, {
                          title: "Are you sure to delete this?",
                          "confirm-button-text": "Yes",
                          "cancel-button-text": "No",
                          onConfirm: ($event: any) => (_ctx.deleteCurrencyItem(index))
                        }, {
                          reference: _withCtx(() => [
                            _createVNode(_component_el_button, {
                              type: "danger",
                              size: "small",
                              circle: "",
                              link: "",
                              icon: _ctx.icons.Delete,
                              plain: "",
                              class: "psp-limits-settings__delete-currency-button"
                            }, null, 8 /* PROPS */, ["icon"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onConfirm"]),
                        _createVNode(_component_el_collapse_item, {
                          name: `item-${index}`,
                          class: "psp-limits-settings__collapse-item"
                        }, {
                          title: _withCtx(() => [
                            _createTextVNode(_toDisplayString(depositLimit.currency ?? 'Currency not selected'), 1 /* TEXT */)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_1, [
                                _createVNode(_component_el_form_item, {
                                  label: "Currency",
                                  prop: 'depositLimitsList.' + index + '.currency',
                                  rules: _ctx.pspLimitsSettingsFormRules.currency,
                                  class: "psp-limits-settings__field"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_select, {
                                      modelValue: depositLimit.currency,
                                      "onUpdate:modelValue": ($event: any) => ((depositLimit.currency) = $event),
                                      placeholder: "Select currency"
                                    }, {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableCurrencies, (currency) => {
                                          return (_openBlock(), _createBlock(_component_el_option, {
                                            key: currency.code,
                                            label: currency.code,
                                            value: currency.code
                                          }, null, 8 /* PROPS */, ["label", "value"]))
                                        }), 128 /* KEYED_FRAGMENT */))
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "rules"]),
                                _createVNode(_component_el_checkbox, {
                                  modelValue: depositLimit.hidePaymentMethod,
                                  "onUpdate:modelValue": ($event: any) => ((depositLimit.hidePaymentMethod) = $event),
                                  border: "",
                                  class: "psp-limits-settings__checkbox",
                                  label: "Hide PM once limit is reached"
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]),
                                _createVNode(_component_el_checkbox, {
                                  modelValue: depositLimit.pendingInclude,
                                  "onUpdate:modelValue": ($event: any) => ((depositLimit.pendingInclude) = $event),
                                  border: "",
                                  class: "psp-limits-settings__checkbox",
                                  label: "Include pending trx"
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _createElementVNode("div", _hoisted_2, [
                                _createVNode(_component_PspLimit, {
                                  "limit-index": index,
                                  "limit-type-key": "limitDaily",
                                  class: "psp-limits-settings__wrapper-limits_item",
                                  title: "PSP Daily limit"
                                }, null, 8 /* PROPS */, ["limit-index"]),
                                _createVNode(_component_PspLimit, {
                                  "limit-index": index,
                                  "limit-type-key": "limitWeekly",
                                  class: "psp-limits-settings__wrapper-limits_item",
                                  title: "PSP Weekly limit"
                                }, null, 8 /* PROPS */, ["limit-index"]),
                                _createVNode(_component_PspLimit, {
                                  "limit-index": index,
                                  "limit-type-key": "limitMonthly",
                                  class: "psp-limits-settings__wrapper-limits_item",
                                  title: "PSP Monthly limit"
                                }, null, 8 /* PROPS */, ["limit-index"])
                              ])
                            ])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name"])
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["model"]))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_el_button, {
          type: "primary",
          icon: _ctx.icons.Plus,
          plain: "",
          class: "psp-limits-settings__add-currency",
          onClick: _ctx.addCurrencyItem
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Add currency ")
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["icon", "onClick"])
      ])), [
        [_directive_loading, _ctx.isPspLimitsSettingsLoading]
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "onClose"]))
}