<template>
  <el-container>
    <el-header class="home-page__header">
      <h3 class="home-page__header_title">
        bo-payments-app-ui
      </h3>
    </el-header>
  </el-container>
</template>

<script src="./home-page.ts" />
<style src="./home-page.scss" />
