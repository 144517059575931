import { createRouter, createWebHistory } from 'vue-router';
import { routes } from './routes/routes';
import { usePermissionsStore } from '@/store/permissions.store';
import { useAuth } from '@/store/auth.store';

const BASE_URL = '/payments/';

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const { paymentsPermissions } = usePermissionsStore();
  const { token } = useAuth();

  if (!token || !paymentsPermissions) {
    throw new Error('Sorry, you are not authorized to access this page');
  }

  const selectedWhitelabelSlug = sessionStorage.getItem('selectedWhitelabelSlug');
  if (!to.query.brand && selectedWhitelabelSlug) {
    return next({ ...to, query: { brand: selectedWhitelabelSlug } });
  }

  next();
});

export default router;
