import { GeoPaymentMethodItem } from '@/models/ps-management/ps-management.model';
import { useGeoPsManagementStore } from '@/store/ps-management/geo-payment-methods.store';
import { useGlobalPsManagementStore } from '@/store/ps-management/global-payment-methods.store';
import { storeToRefs } from 'pinia';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'AddGeoPaymentMethodsDialog',
  setup() {
    const geoPsManagementStore = useGeoPsManagementStore();
    const { closeGeoPaymentMethodsAddingDialog } = geoPsManagementStore;
    const {
      geoPaymentMethodList,
      isAddGeoPaymentMethodsDialogVisible,
    } = storeToRefs(geoPsManagementStore);

    const globalPsManagementStore = useGlobalPsManagementStore();
    const { getGlobalPaymentMethodList } = globalPsManagementStore;
    const {
      globalPaymentMethodList,
      isGlobalPaymentMethodListLoading,
    } = storeToRefs(globalPsManagementStore);

    const selectedPaymentMethods = ref<GeoPaymentMethodItem[]>([]);

    const getAvailableGlobalPaymentMethods = () => {
      return globalPaymentMethodList.value.filter((globalPaymentMethod) => {
        const isGlobalPaymentMethodEnabled = globalPaymentMethod.vaultEnabled;
        const isGlobalPaymentMethodAlreadyAdded = geoPaymentMethodList.value.some((geoPaymentMethod) => {
          return geoPaymentMethod.billingPaymentMethodId === globalPaymentMethod.billingPaymentMethodId;
        });

        return isGlobalPaymentMethodEnabled && !isGlobalPaymentMethodAlreadyAdded;
      });
    };

    const addSelectedPaymentMethodsToGeo = () => {
      if (!selectedPaymentMethods.value.length) {
        return;
      }

      geoPaymentMethodList.value.push(...selectedPaymentMethods.value);
      closeGeoPaymentMethodsAddingDialog();
    };

    return {
      isAddGeoPaymentMethodsDialogVisible,
      closeGeoPaymentMethodsAddingDialog,
      geoPaymentMethodList,
      globalPaymentMethodList,
      isGlobalPaymentMethodListLoading,
      getGlobalPaymentMethodList,
      selectedPaymentMethods,
      getAvailableGlobalPaymentMethods,
      addSelectedPaymentMethodsToGeo,
    };
  },
});
