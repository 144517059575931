import { Ref } from 'vue';
import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import GetTransactionListRequest from '@/api/transactions/payment/get-transaction-list.request';
import GetTransactionListSummaryRequest from '@/api/transactions/payment/get-transaction-list-summary.request';
import GetTransactionsReportRequest from '@/api/transactions/payment/get-transactions-report.request';

export class TransactionsApiResources {
  constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly apiUrl: Ref<string>,
  ) { }

  public get getTransactionListRequest(): GetTransactionListRequest {
    return new GetTransactionListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/payment`,
      HttpMethods.GET,
    );
  }

  public get getTransactionListSummaryRequest(): GetTransactionListSummaryRequest {
    return new GetTransactionListSummaryRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/paymentListTotal`,
      HttpMethods.GET,
    );
  }

  public get getTransactionsReportRequest(): GetTransactionsReportRequest {
    return new GetTransactionsReportRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/payment/csv`,
      HttpMethods.GET,
    );
  }
}
