export type SegmentItem = {
  name: string;
  segmentId: string;
}

export type SegmentListFilter = {
  isPublished: boolean;
  name?: string;
}

export type SegmentListRequestModel = {
  filterList: SegmentListFilter;
}

export const DEFAULT_SEGMENTS_FILTER: SegmentListFilter = {
  isPublished: true,
};
