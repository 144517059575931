import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { BillingProviderItem, GetBillingProviderListPayload } from '@/models/ps-management/billing-provider.model';

export default class GetBillingProviderListRequest extends ApiResourceMethod<BillingProviderItem[]> {
  public perform(params: GetBillingProviderListPayload): Promise<BillingProviderItem[]> {
    return this.performer.requestWithMapper(
      { params },
      (payload: BaseResponse<BillingProviderItem[]>) => {
        return payload.data;
      },
    );
  }
}
