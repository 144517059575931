import { api } from '@/services/http.service';
import { defineStore } from 'pinia';
import { CountryItem } from '@/models/dictionary/country-list.model';
import { ElNotification } from 'element-plus';

interface CountryListStore {
  countryList: CountryItem[];
  isCountryListLoading: boolean;
}

export const useCountryListStore = defineStore({
  id: 'CountryListStore',
  state: (): CountryListStore => ({
    countryList: [],
    isCountryListLoading: false,
  }),
  actions: {
    setCountryListLoading(isLoading: boolean) {
      this.isCountryListLoading = isLoading;
    },
    getCountryList(): Promise<CountryItem[]> {
      if (this.countryList.length) {
        return Promise.resolve(this.countryList);
      }

      this.setCountryListLoading(true);

      return api.dictionary.getCountryListRequest.perform()
        .then(response => {
          this.countryList = response;

          return response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });

          return [];
        }).finally(() => this.setCountryListLoading(false));
    },
  },
});
