import { defineStore } from 'pinia';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import {
  CurrentUploadDataModel,
  DEFAULT_CURRENT_UPLOAD_IMAGE_DATA,
} from '@/models/upload-image.model';

interface UploadImageStoreModel {
  isUploading: boolean;
  selectedImage: File | null;
  currentUploadData: CurrentUploadDataModel;
  oldImageUrl: string;
  isCurrentImageDownloading: boolean;
}

export const useUploadImageStore = defineStore('uploadImageStore', {
  state: (): UploadImageStoreModel => ({
    isUploading: false,
    isCurrentImageDownloading: false,
    selectedImage: null,
    currentUploadData: {
      ...DEFAULT_CURRENT_UPLOAD_IMAGE_DATA,
    },
    oldImageUrl: '',
  }),
  getters: {
    isSelectedImage: state => !!state.selectedImage,
    selectedImagePreview: state => {
      if (!state.selectedImage) return null;

      return URL.createObjectURL(state.selectedImage);
    },
  },
  actions: {
    resetImageData(): void {
      this.selectedImage = null;
      this.oldImageUrl = '';
      this.currentUploadData = {
        ...DEFAULT_CURRENT_UPLOAD_IMAGE_DATA,
      };
    },
    imageInput(event: InputEvent): void {
      const files = (event.target as HTMLInputElement).files as FileList;

      this.setSelectedImage(files[0]);
    },
    setSelectedImage(file: File): void {
      this.selectedImage = file;
    },
    setOldImageUrl(oldImageUrl: string): void {
      this.oldImageUrl = oldImageUrl;
    },
    async uploadFile(): Promise<CurrentUploadDataModel | void> {
      this.isUploading = true;

      const formData = new FormData();
      formData.append('file', this.selectedImage as Blob);

      return api.file.uploadImageRequest.perform(formData).then(({ filePath, fileId }) => {
        ElNotification.success({
          title: 'Successful',
          message: 'Selected image uploaded.',
        });

        this.currentUploadData.uploadedImageFilePath = filePath;
        this.currentUploadData.uploadedImageFileId = fileId;

        return this.currentUploadData;
      }).catch(error => {
        ElNotification.error({
          title: 'Error',
          message: error.response.data.message,
        });
      }).finally(() => {
        this.isUploading = false;
      });
    },
    setCurrentImageDownloading(isLoading: boolean) {
      this.isCurrentImageDownloading = isLoading;
    },
    async getFileUrl(fileId: string): Promise<string> {
      this.setCurrentImageDownloading(true);

      return api.file.getFileUrlByIdRequest
        .perform(fileId)
        .finally(() => {
          this.setCurrentImageDownloading(false);
        });
    },
  },
});
