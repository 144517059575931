import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { TransactionOperatingSystemListItem } from '@/models/dictionary/transaction-operating-system-list.model';

export default class GetTransactionOperationSystemListRequest
  extends ApiResourceMethod<TransactionOperatingSystemListItem[]> {
  public perform(): Promise<TransactionOperatingSystemListItem[]> {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<TransactionOperatingSystemListItem[]>) => {
        return payload.data;
      },
    );
  }
}
