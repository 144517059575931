import { api } from '@/services/http.service';
import { defineStore } from 'pinia';
import { ElNotification } from 'element-plus';
import { BillingProviderItem, DEFAULT_BILLING_PROVIDER_PAYLOAD } from '@/models/ps-management/billing-provider.model';

interface BillingProviderListStore {
  billingProviderList: BillingProviderItem[];
  isBillingProviderListLoading: boolean;
}

export const useBillingProviderListStore = defineStore({
  id: 'BillingProviderListStore',
  state: (): BillingProviderListStore => ({
    billingProviderList: [],
    isBillingProviderListLoading: false,
  }),
  actions: {
    setBillingProviderListLoading(isLoading: boolean): void {
      if (this.isBillingProviderListLoading === isLoading) {
        return;
      }

      this.isBillingProviderListLoading = isLoading;
    },
    getBillingProviderList(): Promise<BillingProviderItem[] | void> {
      if (this.billingProviderList.length) {
        return Promise.resolve(this.billingProviderList);
      }

      this.setBillingProviderListLoading(true);

      return api.psManagement.getBillingProviderListRequest.perform(DEFAULT_BILLING_PROVIDER_PAYLOAD)
        .then(response => {
          this.billingProviderList = response;
        }).catch(error => {
          ElNotification({
            title: 'Error',
            message: error.message,
            type: 'error',
          });
        }).finally(() => this.setBillingProviderListLoading(false));
    },
  },
});
