import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import { CountryItem } from '@/models/dictionary/country-list.model';

export default class GetCountryListRequest extends ApiResourceMethod<CountryItem[]> {
  public perform(): Promise<CountryItem[]> {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<CountryItem[]>) => {
        return payload.data;
      },
    );
  }
}
