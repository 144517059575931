import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue';
import { Setting, Grid } from '@element-plus/icons-vue';
import { cloneDeep } from 'lodash';
import { ColumnFilterEvents, ColumnFilterItem } from '@/models/column-filter/column-filter.model';
import draggable from 'vuedraggable';

export default defineComponent({
  name: 'ColumnFilter',
  components: {
    Setting,
    Grid,
    draggable,
  },
  props: {
    storageKey: {
      type: String,
      required: true,
    },
    originalColumns: {
      type: Array as PropType<ColumnFilterItem[]>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ColumnFilterEvents.UPDATE_COLUMNS, ColumnFilterEvents.RESET_COLUMNS],
  setup(props, { emit }) {
    const columns = ref(cloneDeep(props.originalColumns));

    const checkedColumns = computed({
      get() {
        return columns.value.filter(column => column.isVisible).map(column => column.value);
      },
      set(checked: string[]) {
        columns.value.forEach(column => {
          // eslint-disable-next-line no-param-reassign
          column.isVisible = !!checked.includes(column.value);
        });
      },
    });

    const getColumns = (): ColumnFilterItem[] => {
      const storageData = localStorage.getItem(props.storageKey);

      if (storageData && props.originalColumns.length) {
        return JSON.parse(storageData);
      }

      return cloneDeep(props.originalColumns);
    };

    onMounted(() => {
      columns.value = getColumns();
    });

    watch(() => props.originalColumns, () => {
      columns.value = getColumns();
    });

    const updateColumns = (): void => {
      localStorage.setItem(props.storageKey, JSON.stringify(columns.value));
      emit(ColumnFilterEvents.UPDATE_COLUMNS, columns.value);
    };

    const resetColumns = (): void => {
      columns.value = cloneDeep(props.originalColumns);
      localStorage.removeItem(props.storageKey);
      emit(ColumnFilterEvents.RESET_COLUMNS);
    };

    return {
      Setting,
      checkedColumns,
      updateColumns,
      resetColumns,
      columns,
    };
  },
});
