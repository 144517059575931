import { defineStore, storeToRefs } from 'pinia';
import { useTransactionListFilterStore } from '@/store/transaction/transaction-list-filter.store';
import { clearObjIncludesFields } from '@/utils/form.utils';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { useTransactionListStore } from '@/store/transaction/transaction-list.store';
import { downloadFile } from '@/utils/downlod-file.util';
import { DEFAULT_REPORT_LIMIT } from '@/models/constants';

type TransactionListReportStore = {
  isTransactionListReportLoading: boolean;
}

export const useTransactionListReportStore = defineStore({
  id: 'TransactionListReportStore',
  state: (): TransactionListReportStore => ({
    isTransactionListReportLoading: false,
  }),
  actions: {
    setTransactionListReportLoading(isLoading: boolean): void {
      this.isTransactionListReportLoading = isLoading;
    },
    getTransactionListReport(): Promise<void> {
      this.setTransactionListReportLoading(true);

      const { submittedFilterFormData } = storeToRefs(useTransactionListFilterStore());
      const { sort } = storeToRefs(useTransactionListStore());

      const requestPayload = {
        limit: DEFAULT_REPORT_LIMIT,
        filterList: clearObjIncludesFields(submittedFilterFormData.value),
        sort: sort.value,
      };

      return api.transactions.getTransactionsReportRequest.perform(requestPayload)
        .then((data) => {
          downloadFile(
            `transactions-report-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.csv`,
            'data:text/csv;charset=UTF-8,' + encodeURIComponent(data.fileData),
          );
        })
        .catch((error) => {
          ElNotification({
            title: 'Error',
            message: error,
            type: 'error',
          });
        })
        .finally(() => {
          this.setTransactionListReportLoading(false);
        });
    },
  },
});
