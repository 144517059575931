import { usePsManagementCountryListStore } from '@/store/ps-management/payment-methods-country-list.store';
import { useGeoPsManagementStore } from '@/store/ps-management/geo-payment-methods.store';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, onMounted } from 'vue';
import { Plus, Refresh, EditPen, Sort, Upload } from '@element-plus/icons-vue';
import GlobalPaymentMethodsList
  from '@/layouts/ps-management-page/global-payment-methods-list/global-payment-methods-list.vue';
import GeoPaymentMethodsList
  from '@/layouts/ps-management-page/geo-payment-methods-list/geo-payment-methods-list.vue';
import { useGlobalPsManagementStore } from '@/store/ps-management/global-payment-methods.store';
import { useDragListSorting } from '@/components/drag-sorting/drag-sorting.hook';
import GlobalPaymentMethodsSettings
  from '@/layouts/ps-management-page/global-payment-methods-settings/global-payment-methods-settings.vue';
import { geoPaymentMethodOperationOptions } from '@/models/ps-management/ps-management.model';
import AddCountriesDialog from '@/layouts/ps-management-page/add-countries-dialog/add-countries-dialog.vue';
import AddGeoPaymentMethodsDialog from './add-geo-payment-methods-dialog/add-geo-payment-methods-dialog.vue';
import GeoPaymentMethodsSettings
  from '@/layouts/ps-management-page/geo-payment-methods-settings/geo-payment-methods-settings.vue';
import { usePaymentMethodsDepositLimitsStore } from '@/store/ps-management/payment-methods-deposit-limits.store';
import PspLimitsSettings from '@/layouts/ps-management-page/psp-limits-settings/psp-limits-settings.vue';
import { usePermissionsStore } from '@/store/permissions.store';
import { PS_MANAGEMENT_PERMISSIONS } from '@/models/app-internal/permissions.model';
import { usePaymentTypeListStore } from '@/store/dictionary/payment-type-list.store';

const icons = {
  Plus,
  Refresh,
  EditPen,
  Sort,
  Upload,
};

export default defineComponent({
  name: 'PsManagementPage',
  components: {
    GlobalPaymentMethodsList,
    GeoPaymentMethodsList,
    GlobalPaymentMethodsSettings,
    GeoPaymentMethodsSettings,
    AddCountriesDialog,
    PspLimitsSettings,
    AddGeoPaymentMethodsDialog,
  },
  setup() {
    const psManagementCountryListStore = usePsManagementCountryListStore();
    const { getPsManagementCountryList, openAddCountriesDialog } = psManagementCountryListStore;
    const {
      psManagementCountryList,
      isPsManagementCountryListLoading,
      selectedCountry,
      isGlobalSettingsMode,
      paymentMethodsGeoSelectRef,
    } = storeToRefs(psManagementCountryListStore);

    const geoPsManagementStore = useGeoPsManagementStore();
    const {
      getGeoPaymentMethodList,
      updateFullGeoPaymentMethodList,
      openAddGeoPaymentMethodsDialog,
    } = geoPsManagementStore;
    const {
      geoCurrentOperation,
      isAddGeoPaymentMethodsDialogVisible,
      isUnsavedGeoPaymentMethodList,
      isGeoPaymentMethodSettingsSidebarVisible,
    } = storeToRefs(geoPsManagementStore);

    const globalPsManagementStore = useGlobalPsManagementStore();
    const { getGlobalPaymentMethodList, setGlobalPaymentMethodSettingsSidebarVisible } = globalPsManagementStore;
    const { isGlobalPaymentMethodSettingsSidebarVisible } = storeToRefs(globalPsManagementStore);

    const { isResortingInProgress } = useDragListSorting();

    const { isPspLimitsSettingsSidebarVisible } = storeToRefs(usePaymentMethodsDepositLimitsStore());
    const { psManagementPermissions } = storeToRefs(usePermissionsStore());

    const { getPaymentTypeListRequest } = usePaymentTypeListStore();

    onMounted(() => {
      getPaymentTypeListRequest();
      getPsManagementCountryList();
      refreshPaymentSystemList();
    });

    const pageTitle = computed(() => isGlobalSettingsMode.value
      ? 'Global Payment Methods'
      : `Payment Methods for ${selectedCountry.value?.name as string}`);

    const refreshPaymentSystemList = () => {
      const paymentMethodListRequest = isGlobalSettingsMode.value
        ? () => getGlobalPaymentMethodList()
        : () => getGeoPaymentMethodList();

      paymentMethodListRequest();
    };

    const isUpdateGeoPaymentListButtonVisible = computed(() =>
      !isGlobalSettingsMode.value &&
      (isUnsavedGeoPaymentMethodList.value || isResortingInProgress.value),
    );
    const isCreateGlobalPaymentMethodButtonVisible = computed(() => {
      return psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.CREATE_GLOBAL_PAYMENT_METHOD] &&
      isGlobalSettingsMode.value;
    });
    const isCreateGeoPaymentMethodButtonVisible = computed(() => {
      return psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.UPDATE_GEO_PAYMENT_METHOD_LIST] &&
        !isGlobalSettingsMode.value;
    });
    const isGetGeoPaymentMethodListAllowed = computed(() =>
      psManagementPermissions.value[PS_MANAGEMENT_PERMISSIONS.GET_GEO_PAYMENT_METHOD_LIST],
    );
    const isAddPaymentMethodGeoAllowed = computed(() => psManagementPermissions.value[
      PS_MANAGEMENT_PERMISSIONS.ADD_PAYMENT_METHOD_GEO
    ]);

    return {
      icons,
      getPsManagementCountryList,
      psManagementCountryList,
      isPsManagementCountryListLoading,
      selectedCountry,
      getGeoPaymentMethodList,
      refreshPaymentSystemList,
      isGlobalSettingsMode,
      pageTitle,
      isResortingInProgress,
      updateFullGeoPaymentMethodList,
      isUpdateGeoPaymentListButtonVisible,
      isGlobalPaymentMethodSettingsSidebarVisible,
      setGlobalPaymentMethodSettingsSidebarVisible,
      openAddCountriesDialog,
      paymentMethodsGeoSelectRef,
      geoPaymentMethodOperationOptions,
      geoCurrentOperation,
      isGeoPaymentMethodSettingsSidebarVisible,
      isPspLimitsSettingsSidebarVisible,
      openAddGeoPaymentMethodsDialog,
      isAddGeoPaymentMethodsDialogVisible,
      isUnsavedGeoPaymentMethodList,
      isCreateGlobalPaymentMethodButtonVisible,
      isCreateGeoPaymentMethodButtonVisible,
      isGetGeoPaymentMethodListAllowed,
      isAddPaymentMethodGeoAllowed,
    };
  },
});
